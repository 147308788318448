import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const request = axios.create({
  baseURL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

request.interceptors.response.use(function (response) {
  try {
    return response.data.data;
  } catch {
    return response;
  }
});

export default request;
