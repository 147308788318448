// React Stuffs
import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import { EventContext, LangCodeContext } from '../Context';
// Material UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
// Matierial UI Icons
import TranslateIcon from '@material-ui/icons/Translate';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// Source Files
import { helpWidget, filterLang, langIndex } from '../../Functions';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 500,
  },
  barLeft: {
    width: '250px',
    textAlign: 'left',
  },
  barRight: {
    width: '250px',
    textAlign: 'right',
  },
  floatBottom: {
    position: 'fixed',
    right: '0',
    bottom: '0',
  },
  helpContainer: {
    width: '100%',
    height: '48px',
    marginLeft: '-1rem',
    backgroundColor: 'white',
    position: 'fixed',
    bottom: 0,
  },
}));

function TopBar({ hide, goBack }) {
  // Constants and Context and States
  const classes = useStyles();
  const theme = useTheme();
  const EVENT = useContext(EventContext);
  const { setCode } = useContext(LangCodeContext);
  const desktop = useMediaQuery('(min-width:965px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const filteredLang = filterLang(EVENT.id);
  const [selectedIndex, setSelectedIndex] = useState(langIndex(filteredLang));
  // Effects
  // Functions
  const applyLangIndex = (idx) => {
    setSelectedIndex(idx);
    const lng = filteredLang[idx].code;
    setCode(lng);
    window.localStorage.setItem('locale', lng);
    i18n.changeLanguage(lng);
  };

  const handleClickListItem = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuItemClick = (idx) => {
    applyLangIndex(idx);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (selectedIndex < 0) return null;
  return (
    <div className={classes.root}>
      {!desktop && (
        <div className={classes.helpContainer}>
          <IconButton
            color="inherit"
            onClick={helpWidget}
            className={classes.floatBottom}
          >
            <HelpOutlineIcon style={{ fill: theme.palette.primary.main }} />
          </IconButton>
        </div>
      )}
      <AppBar position="fixed">
        <Toolbar>
          <div className={desktop ? classes.barLeft : null}>
            <IconButton
              color="inherit"
              onClick={goBack}
              style={{ visibility: hide ? 'hidden' : undefined }}
              disabled={hide}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </div>
          <Typography variant="h5" className={classes.title}>
            {EVENT.name}
          </Typography>
          <div className={desktop ? classes.barRight : null}>
            {desktop && (
              <IconButton color="inherit" onClick={helpWidget}>
                <HelpOutlineIcon style={{ fill: 'white' }} />
              </IconButton>
            )}
            <IconButton
              color="inherit"
              onClick={handleClickListItem}
              disabled={desktop}
            >
              <TranslateIcon style={{ fill: 'white' }} />
            </IconButton>
            {desktop && (
              <Select
                value={filteredLang[selectedIndex].name}
                disableUnderline
                style={{ color: 'white' }}
              >
                {filteredLang.map((option, idx) => (
                  <MenuItem
                    key={`SelectItem-${option.name}`}
                    value={option.name}
                    onClick={() => applyLangIndex(idx)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {filteredLang.map((option, index) => (
            <MenuItem
              key={`MenuItem-${option.name}`}
              selected={index === selectedIndex}
              onClick={() => handleMenuItemClick(index)}
            >
              {option.name}
            </MenuItem>
          ))}
        </Menu>
      </AppBar>
    </div>
  );
}

export default TopBar;
