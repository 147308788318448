import rq from './request';

export const appointment = {
  async getAppointment({ app_id }) {
    return rq.get(`/get/appointment/${app_id}`);
  },
  async acceptAppointment({ app_id }) {
    return rq.patch('/accept/appointment', {
      app_id,
    });
  },
  async rejectAppointment({ app_id }) {
    return rq.patch('/reject/appointment', {
      app_id,
    });
  },
  async cancelAppointment(appData) {
    return rq.delete('/delete/appointment', { data: appData });
  },
  async getAllAppointmentByExIdAndRepId({ ex_id, rep_id }) {
    return rq.get(`/get_all/appointment/${ex_id}/${rep_id}`);
  },
};
