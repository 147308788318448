import styled from 'styled-components';

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 24px;
`;

export const ListRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 0px;
  align-items: center;
  width: 100%;
`;

export const ListItemCell = styled.div`
  justify-self: start;
  width: auto;
`;

export const ActionItemCell = styled.div`
  justify-self: end;
  width: auto;
`;
