// React Stuffs
import React, { useContext, useState } from 'react';
import moment from 'moment';
import {
  AttendeeContext,
  ExhibitorContext,
  RepContext,
  LangCodeContext,
  AssetContext,
  HostNameContext,
  CustomContext,
} from '../Context';
import { Redirect, useLocation } from 'react-router-dom';
// Material UI
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Material UI Icons
import EventIcon from '@material-ui/icons/Event';

// Source Files
import isEmail from 'validator/es/lib/isEmail';
import isEmpty from 'validator/es/lib/isEmpty';
import TopBar from './TopBar';
import {
  toLocaleTimeString,
  toLocaleDateString,
  toLocaleDayString,
  getTzString,
  getTimeZoneString,
} from '../../Functions';

import { useParams } from 'react-router';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(10),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '4rem',
    },
    avatar: {
      margin: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    button: {
      marginTop: '1rem',
      height: '56px',
    },
    snackContent: {
      whiteSpace: 'pre-wrap',
    },
  };
});

function SlotTaken({ open, handleClose, handleRedirect }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const action = (
    <Button
      color="primary"
      variant="contained"
      onClick={handleRedirect}
      size="small"
      style={{
        padding: '4px 12px',
      }}
    >
      {t('scheduleAnotherApp')}
    </Button>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      message={t('oopsBooked')}
      ContentProps={{
        className: classes.snackContent,
      }}
      action={action}
    />
  );
}

function CustomRadio({ value, valid, handleChange }) {
  const { t } = useTranslation();

  return (
    <FormControl
      component="fieldset"
      error={valid}
      style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
    >
      <FormLabel component="legend">{t('meetUpMethod')}</FormLabel>
      <RadioGroup
        value={value}
        onChange={handleChange}
        name="method"
        style={{ height: '42px' }}
      >
        <FormControlLabel
          value="ONSITE"
          control={<Radio color="primary" />}
          label={t('onsite')}
        />
        <FormControlLabel
          value="ONLINE"
          control={<Radio color="primary" />}
          label={t('online')}
        />
      </RadioGroup>
      {valid && <FormHelperText>{t('required')}</FormHelperText>}
    </FormControl>
  );
}

// eslint-disable-next-line complexity
function Form({ location }) {
  const { t } = useTranslation();
  // Constants, Context and States
  const { repIdx } = useParams();
  const classes = useStyles();
  const ATTENDEE = useContext(AttendeeContext);
  const EX = useContext(ExhibitorContext);
  const REPS = useContext(RepContext);
  const CODE = useContext(LangCodeContext).code;
  const ASSET = useContext(AssetContext);
  const CUSTOM = useContext(CustomContext);
  const HOSTNAME = useContext(HostNameContext);
  const [appId, setAppId] = useState(null);
  const [direct, setDirect] = useState(false);
  const [back, setBack] = useState(false);
  const [open, setOpen] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [valid, setValid] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [details, setDetails] = useState({
    attendeeUuid: ATTENDEE?.uuid || null,
    attendeeRepId: ATTENDEE?.rep_id || null,
    attendeeExId: ATTENDEE?.ex_id || null,
    name: ATTENDEE?.name || '',
    last: ATTENDEE?.lastName || '',
    first: ATTENDEE?.firstName || '',
    company: ATTENDEE?.company || '',
    email: ATTENDEE?.email || '',
    notes: '',
    method: '',
  });

  const { hasApprovalMechanism } = useFeatureFlag();

  // Effects
  // Functions
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleTrackingToReport = async (
    eventType,
    eventSubType,
    eventAction,
    eventInfo,
  ) => {
    const trackingObj = {
      event_type: eventType,
      event_subtype: eventSubType,
      event_action: eventAction,
      event_info: eventInfo || '',
      item_id: EX.boothId,
      item_name: EX?.name || '',
      user_token: ATTENDEE?.authToken || '',
      user_email: ATTENDEE?.email || '',
      user_first_name: ATTENDEE?.firstName || '',
      user_last_name: ATTENDEE?.lastName || '',
      user_phone: ATTENDEE?.phone || '',
      user_uuid: ATTENDEE?.uuid || '',
      user_company: ATTENDEE?.company || '',
      user_title: ATTENDEE?.title || '',
      user_role: ATTENDEE?.role || '',
    };

    const toPost = {
      reportDomain: HOSTNAME,
      trackingObject: trackingObj,
    };

    await fetch(
      `${process.env.REACT_APP_VEXPO_REPORT_ENDPOINT}/trackingItemToFirebase`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toPost),
      },
    )
      .then((val) => val.json())
      .catch((e) => console.error(e.message));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const valCheck = [
      isEmpty(details.first, { ignore_whitespace: true }),
      isEmpty(details.last, { ignore_whitespace: true }),
      isEmpty(details.email, { ignore_whitespace: true }),
      !isEmail(details.email),
      isEmpty(details.company, { ignore_whitespace: true }),
      CUSTOM ? isEmpty(details.method) : false,
    ];

    if (hasApprovalMechanism) {
      valCheck[0] = false;
      valCheck[1] = false;
      valCheck.push(isEmpty(details.name, { ignore_whitespace: true }));
    } else {
      details.name = `${details.first} ${details.last}`;
    }

    setValid(valCheck);
    if (valCheck.reduce((accum, bool) => accum || bool, false)) {
      setProcessing(false);
      return;
    }

    const toPost = {
      ...details,
      ex_id: EX.id,
      rep_id: REP.id,
      start: SELECTED_START.valueOf(),
      end: SELECTED_END.valueOf(),
      slot_id,
      zone: getTzString(),
      locale: CODE,
      method: CUSTOM ? details.method : 'DEFAULT',
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/create/appointment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toPost),
      },
    )
      .then((val) => val.json())
      .catch((e) => {
        console.log(e.message);
      });
    setProcessing(false);
    if (res.success) {
      if (EX?.boothId) {
        await handleTrackingToReport('Booth', 'Meetup', 'Submitted_Meetup', '');
      }

      setAppId(res.id);
      setDirect(true);
    } else if (res.message === 'Slot is taken') setOpen(true);
    else alert(`Failed to schedule appointment: ${res.message}`);
  };

  const { search } = useLocation();
  if (search === undefined || repIdx === undefined) {
    return <Redirect to={`/appointment/${EX.id}/all`} />;
  }

  const { idx, slot_id, date } = location.data;
  const REP_IDX = repIdx;
  const REP = REPS[REP_IDX];
  const SELECTED_START = ASSET.dateIndex(moment(date), idx);
  const SELECTED_END = SELECTED_START.clone().add(
    ASSET.SLOT_DURATION,
    'minutes',
  );
  const previous = `/appointment/${EX.id}/R${REP_IDX}/select?date=${moment(
    date,
  ).format('YYYY-MM-DD')}`;

  if (back) return <Redirect to={previous} />;

  if (direct)
    return (
      <Redirect
        to={{
          pathname: `/appointment/${EX.id}/confirm`,
          data: {
            repIdx: REP_IDX,
            start: SELECTED_START.clone(),
            appId,
            attendeeEmail: details.email,
          },
        }}
      />
    );

  return (
    <Container component="main" maxWidth="sm">
      <TopBar hide={false} goBack={() => setBack(true)} />
      <div className={classes.paper}>
        <SlotTaken
          open={open}
          handleClose={() => setOpen(false)}
          handleRedirect={() => {
            window.location.replace(
              `${window.location.pathname.replace('form', 'select')}`,
            );
          }}
        />
        <Avatar className={classes.avatar}>
          <EventIcon />
        </Avatar>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h6">
            {t('from', { representative: REP.name, exhibitor: EX.name })}
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 400 }}>
            {`${toLocaleDateString(SELECTED_START, CODE)} (${toLocaleDayString(
              SELECTED_START,
              CODE,
            )})`}
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 400 }}>
            {`${toLocaleTimeString(
              SELECTED_START,
              CODE,
            )} - ${toLocaleTimeString(SELECTED_END, CODE)} (${getTimeZoneString(
              CODE,
            )})`}
          </Typography>
          <Typography variant="subtitle1">{t('enterDetails')}</Typography>
        </div>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {hasApprovalMechanism ? (
              <TextField
                variant="outlined"
                margin="normal"
                required
                id="name"
                error={valid[6]}
                helperText={valid[6] ? t('required') : undefined}
                label={t('fullName')}
                name="name"
                autoComplete="name"
                value={details.name}
                onInput={handleChange}
                defaultValue={ATTENDEE?.name}
                fullWidth
              />
            ) : (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  style={{ width: 'calc(50% - 8px)' }}
                  id="first"
                  error={valid[0]}
                  helperText={valid[0] ? t('required') : undefined}
                  label={t('first')}
                  name="first"
                  autoComplete="first"
                  value={details.firstName}
                  onInput={handleChange}
                  defaultValue={ATTENDEE?.firstName}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  style={{ width: 'calc(50% - 8px)' }}
                  id="last"
                  error={valid[1]}
                  helperText={valid[1] ? t('required') : undefined}
                  label={t('last')}
                  name="last"
                  autoComplete="last"
                  value={details.lastName}
                  onInput={handleChange}
                  defaultValue={ATTENDEE?.lastName}
                />
              </>
            )}
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('emailLabel')}
            error={valid[2] || valid[3]}
            helperText={
              valid[2] ? t('required') : valid[3] ? t('invEmail') : undefined
            }
            name="email"
            autoComplete="email"
            value={details.email}
            onInput={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="company"
            label={t('company')}
            error={valid[4]}
            helperText={valid[4] ? t('required') : undefined}
            name="company"
            autoComplete="company"
            value={details.company}
            onInput={handleChange}
          />
          {CUSTOM && (
            <CustomRadio
              value={details.method}
              handleChange={handleChange}
              valid={valid[5]}
            />
          )}
          <Typography variant="body1" style={{ marginBottom: '0.5rem' }}>
            {t('additionalNotes')}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="notes"
            name="notes"
            label={t('note')}
            multiline
            rows={4}
            value={details.note}
            onInput={handleChange}
            style={{ marginTop: 0 }}
          />
          <Button
            className={classes.button}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isProcessing}
          >
            {t('scheduleApp')}
            {isProcessing && (
              <CircularProgress
                size={25}
                color="inherit"
                style={{ marginLeft: '1rem' }}
              />
            )}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default Form;
