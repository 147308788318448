import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  snackContent: {
    whiteSpace: 'pre-wrap',
  },
}));

function Snack({ open, onClose, text, buttonText }) {
  const classes = useStyles();
  const action = (
    <Button
      color="primary"
      variant="contained"
      onClick={onClose}
      size="small"
      style={{
        padding: '4px 12px',
      }}
    >
      {buttonText}
    </Button>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      message={text}
      ContentProps={{
        className: classes.snackContent,
      }}
      action={action}
    />
  );
}

export default Snack;
