const AppointmentStatus = {
  NORMAL: 'NORMAL',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
};

const AppointmentLabel = {
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
};

const ConfirmationType = {
  CANCEL: 'cancel',
  REJECT: 'reject',
};

const getStatusLabel = (status) => {
  switch (status) {
    case AppointmentStatus.NORMAL:
    case AppointmentStatus.PENDING:
      return AppointmentLabel.PENDING;

    case AppointmentStatus.CONFIRMED:
      return AppointmentLabel.CONFIRMED;

    case AppointmentStatus.REJECTED:
      return AppointmentLabel.REJECTED;

    case AppointmentStatus.CANCELLED:
      return AppointmentLabel.CANCELLED;

    default:
      return '';
  }
};

export { AppointmentStatus, ConfirmationType, getStatusLabel };
