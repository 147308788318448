import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { toLocaleTimeString } from '../../Functions';
import { LangCodeContext } from '../Context';
import { useTranslation } from 'react-i18next';
import Snack from './Snack';

const useStyles = makeStyles((theme) => ({
  slot: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabledSlot: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(230, 230, 230, 0.2)',
    color: 'grey',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  booked: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    width: '85px',
    borderRadius: '10px',
    height: '30px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  hidden: {
    width: '85px',
    visibility: 'hidden',
  },
  checkBox: {
    width: '85px',
    justifyContent: 'end',
    display: 'flex',
  },
}));

function CalendarSlot(props) {
  const { t } = useTranslation();
  const CODE = useContext(LangCodeContext);
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const [checked, setChecked] = useState(props.available);
  const [open, setOpen] = useState(false);
  // Const available = props.booked && moment().valueOf() < props.slot.valueOf();
  function handleCheck() {
    if (props.booked) {
      setOpen(true);
      return;
    }

    props.handleChange(checked, props.id);
    props.localChange(checked, props.id);
    setChecked(!checked);
  }

  useEffect(() => {
    if (props.erase && checked) {
      props.localChange(true, props.id);
      props.handleChange(true, props.id);
      setChecked(false);
    }
  }, [checked, props, props.erase]);

  useEffect(() => {
    if (props.revert && !checked) {
      props.localChange(false, props.id);
      props.handleChange(false, props.id);
      setChecked(true);
    }
  }, [checked, props, props.revert]);

  return (
    <Grid key={`${props.slot.format('YYYY-MM-DD')}-${props.idx}`} item xs={12}>
      <Snack
        open={open}
        onClose={() => setOpen(false)}
        text={t('slotErrorMessage')}
        buttonText={t('close')}
      />
      <Paper
        className={props.booked ? classes.disabledSlot : classes.slot}
        elevation={hover ? 20 : 2}
        onMouseEnter={props.booked ? undefined : () => setHover(true)}
        onMouseLeave={props.booked ? undefined : () => setHover(false)}
        onClick={handleCheck}
      >
        <div className={classes.checkBox}>
          <Checkbox
            value={props.idx}
            color="primary"
            checked={checked}
            disabled={props.booked}
          />
        </div>
        <Typography variant="body1">
          {`${toLocaleTimeString(props.slot, CODE)}`}
        </Typography>
        <div className={props.booked ? classes.booked : classes.hidden}>
          <Typography variant="body1">{t('booked')}</Typography>
        </div>
      </Paper>
    </Grid>
  );
}

export default CalendarSlot;
