import React, { useContext } from 'react';
import { LangCodeContext } from '../Context';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { toLocaleDayString } from '../../Functions';

const useStyles = makeStyles(() => ({
  subheading: {
    padding: '8px 8px 8px 8px',
    textAlign: 'center',
    border: '1px solid rgb(180, 180, 180)',
    marginLeft: '-1px',
    borderRadius: 0,
  },
}));

function CalendarHeadings(props) {
  const CODE = useContext(LangCodeContext);
  const HEADINGS = Array.from(props.week, (val) =>
    toLocaleDayString(val.date, CODE),
  );
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      {HEADINGS.map((head) => {
        return (
          <Grid key={`sub-${head}`} item xs>
            <Paper className={classes.subheading}>{head}</Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CalendarHeadings;
