// React Stuffs
import React, { useContext, useEffect, useState } from 'react';
import { AssetContext } from '../Context';
import { Redirect } from 'react-router-dom';
// Material UI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
// Matierial UI Icons
import EmailIcon from '@material-ui/icons/Email';
// Source Files
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { timeZero } from '../../Functions';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '95%',
    wordBreak: 'break-all',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardDisabled: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgba(230, 230, 230, 0.2)',
    wordBreak: 'break-all',
    width: '95%',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  disabledText: {
    color: 'grey',
    flexGrow: 1,
  },
  disabledTag: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: '0 1rem',
    borderRadius: '10px',
    fontWeight: 400,
  },
  buttonLabel: {
    color: theme.palette.primary.main,
    marginLeft: '0.5rem',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  container: {
    marginTop: theme.spacing(1),
  },
  disabledNameContainer: {
    width: 'calc(100% - 125px)',
  },
  button: {
    padding: '5px 10px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '125px',
    justifyContent: 'flex-end',
  },
}));

function checkAvailability(date, unavailable, ASSET) {
  const slotIds = ASSET.getDateSlotIds(date);
  const unavailIds = _.intersection(slotIds, unavailable);
  const difference = _.difference(slotIds, unavailIds);
  if (difference.length > 0) {
    const lastId = Math.max(...difference);
    if (
      lastId !== undefined &&
      moment().valueOf() > ASSET.dateFromSlotId(lastId).valueOf()
    )
      return false;
  }

  if (unavailIds.length === slotIds.length) return false;
  return true;
}

function RepCard({ rep, idx }) {
  // Constants, Context and States
  const classes = useStyles();
  const ASSET = useContext(AssetContext);
  const { t } = useTranslation();
  const { name, desc } = rep;
  const UNAVAILABLE = _.concat(
    rep.veto,
    rep.appointments.map((app) => app.slot_id),
  );
  const [hover, setHover] = useState(false);
  const [direct, setDirect] = useState(false);
  const [available, setAvailable] = useState(true);
  // Effects
  useEffect(() => {
    const NOW = moment() > ASSET.START ? timeZero() : timeZero(ASSET.START);
    const END = moment(ASSET.END);
    // Override for testing
    // END.year(2020).month(6).date(31);
    const numDays = END.diff(NOW, 'd');
    let accumulator = false;
    for (let i = 0; i < numDays + 1; ++i) {
      const bool = checkAvailability(
        NOW.clone().add(i, 'd'),
        UNAVAILABLE,
        ASSET,
      );
      accumulator = accumulator || bool;
    }

    setAvailable(accumulator);
  }, [ASSET, UNAVAILABLE]);
  // Functions
  if (direct) {
    return (
      <Redirect
        to={window.location.pathname.replace('/all', `/R${idx}/select`)}
      />
    );
  }

  return (
    <Paper
      className={available ? classes.card : classes.cardDisabled}
      elevation={available ? (hover ? 10 : 2) : 1}
      onMouseEnter={() => {
        if (available) setHover(true);
      }}
      onMouseLeave={() => {
        if (available) setHover(false);
      }}
      onClick={() => {
        if (available) setDirect(true);
      }}
    >
      <div style={{ display: 'flex' }}>
        <div className={available ? null : classes.disabledNameContainer}>
          <Typography
            variant="h6"
            className={available ? null : classes.disabledText}
          >
            {name}
          </Typography>
        </div>
        {!available && (
          <div className={classes.tagContainer}>
            <Typography variant="subtitle1" className={classes.disabledTag}>
              {t('fullyBooked')}
            </Typography>
          </div>
        )}
      </div>
      <Typography
        variant="body1"
        className={available ? null : classes.disabledText}
      >
        {desc}
      </Typography>
      {!available && (
        <div className={classes.container}>
          <Button
            variant="outlined"
            color="primary"
            href={`mailto:${rep.email}`}
            className={classes.button}
          >
            <EmailIcon className={classes.icon} />
            <Typography variant="body1" className={classes.buttonLabel}>
              {t('leaveMessage')}
            </Typography>
          </Button>
        </div>
      )}
    </Paper>
  );
}

export default RepCard;
