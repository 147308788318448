// React Stuffs
import React, { useContext, useState } from 'react';
import {
  LangCodeContext,
  RepContext,
  AssetContext,
  AttendeeContext,
} from '../Context';
import { Redirect } from 'react-router-dom';
// Material UI
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Material UI Icons
// Source Files
import { concat, intersection } from 'lodash';
import moment from 'moment';
import OutsideDetector from './OutsideDetector';
import { toLocaleTimeString } from '../../Functions';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const useStyles = makeStyles((theme) => ({
  slot: {
    padding: theme.spacing(2),
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    width: '100%',
    height: '56px',
  },
  container: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  label: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
}));

function Slot({ data, selected, handleSelect, handleConfirm, desktop }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const CODE = useContext(LangCodeContext).code;
  const { idx, id, time } = data;
  return moment().valueOf() < time.valueOf() ? (
    <Container
      className={classes.container}
      style={desktop ? null : { paddingLeft: 0, paddingRight: 0 }}
    >
      <Paper
        className={classes.slot}
        elevation={2}
        onClick={() => handleSelect(idx, id)}
        style={{ width: selected ? 'calc(50% - 40px)' : '100%' }}
      >
        <Typography variant="body1">
          {toLocaleTimeString(time, CODE)}
        </Typography>
      </Paper>
      {selected && (
        <Grow in={selected}>
          <Button
            style={{ width: 'calc(50% - 8px)' }}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleConfirm}
          >
            {t('confirm')}
          </Button>
        </Grow>
      )}
    </Container>
  ) : null;
}

function Slots({ date, repIdx }) {
  const { t } = useTranslation();

  const REP = useContext(RepContext)[repIdx];
  const desktop = useMediaQuery('(min-width:965px)');
  const ASSET = useContext(AssetContext);
  const ATTENDEE = useContext(AttendeeContext);
  const DATE = moment(date, 'YYYY-MM-DD')
    .hour(ASSET.START.hour())
    .minute(ASSET.START.minute());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [direct, setDirect] = useState(false);

  const { hasApprovalMechanism } = useFeatureFlag();

  // Effects
  // Functions
  function passToSlots(idx, id) {
    setSelectedSlot(idx);
    setSelectedId(id);
    if (idx === selectedSlot) {
      setSelectedSlot(null);
      setSelectedId(null);
    }
  }

  const availableSlots = () => {
    const appointments =
      (hasApprovalMechanism ? REP.confirmed : REP.appointments) ?? [];
    const DATE_SEQ = ASSET.getDateSequence(DATE);
    let unavailable = concat(
      REP.veto,
      appointments.map((app) => app.slot_id),
    );

    // Should block timeslot if inviter has confirmed appointments
    if (ATTENDEE && ATTENDEE.confirmed && ATTENDEE.confirmed.length) {
      unavailable = [
        ...new Set(
          concat(
            unavailable,
            ATTENDEE.confirmed.map((app) => app.slot_id),
          ),
        ),
      ];
    }

    const slotIds = ASSET.getDateSlotIds(DATE);
    const unavailDate = intersection(slotIds, unavailable);
    const availDate = slotIds.filter((id) => !unavailDate.includes(id));
    let j = 0;
    const availArray = [];
    for (let i = 0; i < slotIds.length; ++i)
      if (slotIds[i] === availDate[j]) {
        j += 1;
        availArray.push(DATE_SEQ[i]);
      }

    return availArray.map((val, idx) => {
      return { idx: val, id: availDate[idx], time: ASSET.dateIndex(DATE, val) };
    });
  };

  if (direct) {
    return (
      <Redirect
        to={{
          pathname: `${window.location.pathname.replace('select', 'form')}`,
          data: {
            slot_id: selectedId,
            idx: selectedSlot,
            date: DATE.clone(),
          },
        }}
      />
    );
  }

  return (
    <div>
      <Typography
        variant="subtitle1"
        style={{ textAlign: 'center', marginBottom: '0.5rem' }}
      >
        {t('duration', {
          duration: ASSET.SLOT_DURATION,
        })}
      </Typography>
      <OutsideDetector callback={() => setSelectedSlot(null)}>
        <div style={{ marginBottom: '4rem' }}>
          {availableSlots().map((slot) => {
            return (
              <Slot
                key={`Slot-${slot.idx}`}
                data={slot}
                selected={selectedSlot === slot.idx}
                handleSelect={(idx, id) => passToSlots(idx, id)}
                handleConfirm={() => setDirect(true)}
                desktop={desktop}
              />
            );
          })}
        </div>
      </OutsideDetector>
    </div>
  );
}

export default Slots;
