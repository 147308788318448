import i18n from 'i18next';
import React, { useContext, useState, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  ExhibitorContext,
  EventContext,
  RepContext,
  LangCodeContext,
  AppContext,
} from '../../Context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { langIndex, helpWidget, filterLang } from '../../../Functions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TranslateIcon from '@material-ui/icons/Translate';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CalendarTools } from '@carbon/icons-react';

import List from './List';

const ManageScheduleButton = styled(Button)`
  font-size: 14px;
  background-color: rgba(216, 12, 36, 0.08);
  color: #d80c24;

  &:hover {
    background: rgba(216, 12, 36, 0.18);
  }
`;

const HeaderContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

const HeaderTitleContainer = styled.div`
  width: 70%;
`;

const HeaderTitleLabel = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
`;

const HeaderTitleDesc = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #616161;
`;

const useStyles = makeStyles((theme) => ({
  container: { minWidth: '1200px', overflow: 'auto' },
  paper: {
    marginTop: theme.spacing(9),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 500,
  },
  toolbar: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: '10px',
  },
}));

function AppointmentList() {
  const { t } = useTranslation();

  const EVENT = useContext(EventContext);
  const EX = useContext(ExhibitorContext);
  const REPS = useContext(RepContext);
  const { data: APPS, refetch, lastUpdated } = useContext(AppContext);
  const classes = useStyles();
  const filteredLang = filterLang(EVENT?.id);
  // For Translation
  const [selectedIndex, setSelectedIndex] = useState(langIndex(filteredLang));
  const CODE = filteredLang[selectedIndex].code;

  // eslint-disable-next-line no-undef
  if (typeof ze === 'function') zE('webWidget', 'setLocale', CODE);

  function changeLanguage(idx) {
    setSelectedIndex(idx);
    const lng = filteredLang[idx].code;
    window.localStorage.setItem('locale', lng);
    i18n.changeLanguage(lng);
    refetch();
  }

  const handleClickManageSchedule = useCallback(() => {
    window.location.href = `/exhibitor/${EX.id}/edit`;
  }, [EX.id]);

  return (
    <LangCodeContext.Provider value={CODE}>
      <Container component="main" maxWidth="lg" className={classes.container}>
        {EVENT !== null && EX !== null && REPS !== null && APPS !== null ? (
          <div>
            <div className={classes.root}>
              <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                  <div style={{ width: '250px', textAlign: 'left' }}>
                    <IconButton color="inherit" disabled></IconButton>
                  </div>
                  <div>
                    <Typography variant="subtitle1" className={classes.title}>
                      {EVENT.name}
                    </Typography>
                    <Typography variant="h5" className={classes.title}>
                      {t('title')}
                    </Typography>
                  </div>

                  <div style={{ width: '250px', textAlign: 'right' }}>
                    <IconButton color="inherit" onClick={helpWidget}>
                      <HelpOutlineIcon style={{ fill: 'white' }} />
                    </IconButton>
                    <IconButton color="inherit" disabled>
                      <TranslateIcon style={{ fill: 'white' }} />
                    </IconButton>
                    <Select
                      value={filteredLang[selectedIndex].name}
                      disableUnderline
                      style={{ color: 'white' }}
                    >
                      {filteredLang.map((option, idx) => (
                        <MenuItem
                          key={option.name}
                          value={option.name}
                          onClick={() => changeLanguage(idx)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Toolbar>
              </AppBar>
            </div>

            <div className={classes.paper}>
              <HeaderContainer>
                <HeaderTitleContainer>
                  <HeaderTitleLabel>{t('myAppointment')}</HeaderTitleLabel>
                  <HeaderTitleDesc>{t('myAppointmentDesc')}</HeaderTitleDesc>
                </HeaderTitleContainer>

                <ManageScheduleButton
                  disableElevation
                  className={classes.button}
                  type="submit"
                  variant="contained"
                  onClick={handleClickManageSchedule}
                >
                  <CalendarTools size={20} className={classes.icon} />
                  {t('manageMySchedule')}
                </ManageScheduleButton>
              </HeaderContainer>

              <List
                apps={APPS}
                CODE={CODE}
                REPS={REPS}
                lastUpdated={lastUpdated}
                eventId={EVENT.id}
              />
            </div>
          </div>
        ) : (
          <div className="abs-center">
            <CircularProgress />
          </div>
        )}
      </Container>
    </LangCodeContext.Provider>
  );
}

export default AppointmentList;
