import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import './index.css';
import App from './App';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(<App />);
