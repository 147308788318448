import React, { useCallback, useContext, useState } from 'react';
import {
  Container,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MailOutlined, CheckCircle, Cancel } from '@material-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import { google, ics } from 'calendar-link';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { api } from '../../services/api';
import {
  EventContext,
  AppContext,
  RepContext,
  ExhibitorContext,
  LangCodeContext,
} from '../Context';
import {
  toLocaleDateString,
  toLocaleDayString,
  toLocaleTimeString,
  getTimeZoneString,
} from '../../Functions';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 40,
      marginBottom: 8,
    },
    '& .icon-green': {
      color: '#37CC85',
    },
    '& .icon-red': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-subtitle1': {
      fontWeight: 700,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    padding: 16,
    margin: '16px 0',
    '& .MuiTypography-subtitle1': {
      fontWeight: 700,
    },
  },
  details: {
    width: '100%',
    '& p': {
      fontSize: 14,
      lineHeight: '143%',
    },
  },
  buttonWrapper: {
    display: 'flex',
    gap: 16,
    marginBottom: 16,
  },
  buttonFlex: {
    width: '100%',
    display: 'flex',
    gap: 16,
  },
  buttonCancel: {
    background: 'rgba(216, 12, 36, 0.08)',
  },
  loadingCircle: {
    marginLeft: '1rem',
  },
}));

const APPOINTMENT_STATUS = {
  pending: 'PENDING',
  confirmed: 'CONFIRMED',
  rejected: 'REJECTED',
  cancelled: 'CANCELLED',
};

function Details() {
  const { t } = useTranslation();

  const params = useParams();
  const APP_ID = params.id;

  const history = useHistory();
  const classes = useStyles();

  const CODE = useContext(LangCodeContext).code;

  const { data: app, refetch: appRefetch } = useContext(AppContext);

  const event = useContext(EventContext);
  const rep = useContext(RepContext);
  const ex = useContext(ExhibitorContext);

  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const [isRejectLoading, setRejectLoading] = useState(false);

  const handleAccept = useCallback(async () => {
    setConfirmLoading(true);
    await api.appointment.acceptAppointment({
      app_id: APP_ID,
    });
    appRefetch();
    setConfirmLoading(false);
  }, [APP_ID, appRefetch]);

  const handleReject = useCallback(async () => {
    setRejectLoading(true);
    await api.appointment.rejectAppointment({
      app_id: APP_ID,
    });
    appRefetch();
    setRejectLoading(false);
  }, [APP_ID, appRefetch]);

  const handleCancel = useCallback(() => {
    history.push(`/cancel/${app.id}?initiator=${app.rep_id}`);
  }, [app.id, app.rep_id, history]);

  const handleRedirect = useCallback(() => {
    const auth_token = window.localStorage.getItem(ex.id);
    history.push(`/exhibitor/${ex.id}?auth_token=${auth_token}`);
  }, [ex.id, history]);

  const start = moment(app.start);
  const end = start.clone().add(event.slot_duration, 'minutes');

  const calendarData = {
    title: t('icsTitle', {
      representative: app.attendee.name,
      exhibitor: ex.name,
    }),
    description:
      event.id === 'Jp3xqJBO3YmpcUqZYtiO' || event.id === 'tuPs3Cox4GYXCp5ryiCs'
        ? ''
        : app.meetingLink ?? rep.link,
    start: start.valueOf(),
    end: end.valueOf(),
  };

  const status = {
    [APPOINTMENT_STATUS.pending]: {
      icon: <MailOutlined className="icon-green" />,
      label: t('statusInvitation'),
      description: t('statusInvitationDesc', { name: event.name }),
    },
    [APPOINTMENT_STATUS.confirmed]: {
      icon: <CheckCircle className="icon-red" />,
      label: t('statusConfirmed'),
      description: t('statusConfirmedDesc'),
    },
    [APPOINTMENT_STATUS.cancelled]: {
      icon: <Cancel className="icon-red" />,
      label: t('statusCancelled'),
      description: t('statusCancelledDesc'),
    },
    [APPOINTMENT_STATUS.rejected]: {
      icon: <Cancel className="icon-red" />,
      label: t('statusRejected'),
      description: t('statusRejectedDesc'),
    },
  };

  const { icon, label, description } = status[app.status] ?? {};

  const isStatusConfirmed = APPOINTMENT_STATUS.confirmed === app.status;
  const isStatusPending = APPOINTMENT_STATUS.pending === app.status;

  const isButtonDisabled = isConfirmLoading || isRejectLoading;

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.heading}>
        {icon}
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body1">{description}</Typography>
      </div>
      <div className={classes.content}>
        <Typography variant="subtitle1">Appointment Details</Typography>
        <div className={classes.details}>
          <p>
            {`${t('date')}${toLocaleDateString(
              app.start,
              CODE,
            )} (${toLocaleDayString(app.start, CODE)})`}
            <br />
            {`${t('time')}${toLocaleTimeString(
              app.start,
              CODE,
            )} - ${toLocaleTimeString(app.end, CODE)} (${getTimeZoneString(
              CODE,
            )})`}
            <br />
            {app.attendee.company
              ? t('detailsInviterNameCompany', {
                  fullName: app.attendee.name,
                  company: app.attendee.company,
                })
              : t('detailsInviterName', {
                  fullName: app.attendee.name,
                })}
            {isStatusPending && app.notes && (
              <>
                <br />
                {t('notes', { note: app.notes })}
              </>
            )}
          </p>
        </div>
        {isStatusConfirmed && (
          <div className={classes.buttonFlex}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                const googleCalendar = google(calendarData);
                const win = window.open(googleCalendar);
                win.focus();
              }}
            >
              {t('addToGoogle')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                saveAs(ics(calendarData), `${calendarData.title}.ics`);
              }}
            >
              {t('addToOthers')}
            </Button>
          </div>
        )}
      </div>
      {isStatusPending && (
        <div className={classes.buttonWrapper}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleReject}
            disabled={isButtonDisabled}
            fullWidth
          >
            {t('rejectBtn')}
            {isRejectLoading && (
              <CircularProgress
                className={classes.loadingCircle}
                size={25}
                color="inherit"
              />
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAccept}
            disabled={isButtonDisabled}
            fullWidth
          >
            {t('acceptBtn')}
            {isConfirmLoading && (
              <CircularProgress
                className={classes.loadingCircle}
                size={25}
                color="inherit"
              />
            )}
          </Button>
        </div>
      )}
      {isStatusConfirmed && (
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            onClick={handleCancel}
            className={classes.buttonCancel}
            disabled={isButtonDisabled}
            fullWidth
          >
            {t('cancelBtn')}
          </Button>
        </div>
      )}
      <Button
        fullWidth
        variant={isStatusPending ? 'text' : 'outlined'}
        color="primary"
        onClick={handleRedirect}
        disabled={isButtonDisabled}
      >
        {t('goToAppointments')}
      </Button>
    </Container>
  );
}

export default Details;
