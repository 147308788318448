import React from 'react';

import styled from 'styled-components';

import { ListRow, ListItemCell, ActionItemCell } from './ListBaseRow';

const ListContent = styled(ListRow)`
  min-height: 56px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const StatusListItemCell = styled(ListItemCell)`
  text-align: start;
  min-width: 280px;
`;

const GeneralListRow = ({ rowItems }) => {
  return (
    <ListContent>
      {rowItems.map(({ content, type }, index) => {
        if (type === 'action') {
          return <ActionItemCell key={index}>{content}</ActionItemCell>;
        }

        if (type === 'status') {
          return <StatusListItemCell key={index}>{content}</StatusListItemCell>;
        }

        return <ListItemCell key={index}>{content}</ListItemCell>;
      })}
    </ListContent>
  );
};

export default GeneralListRow;
