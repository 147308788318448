import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CalendarItem from './CalendarItem';
import CalendarHeadings from './CalendarHeadings';
import { LangCodeContext } from '../Context';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { toLocaleYearMonthString } from '../../Functions';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  row: {
    marginTop: '-1px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
}));

function momentArray(ASSET, locale) {
  const start = moment(ASSET.START);
  const end = moment(ASSET.END);
  const cmpStart = moment(start).hour(0).minute(0);
  const cmpEnd =
    end.hour() === 0 && end.minute() === 0
      ? moment(end).hour(0).minute(0)
      : moment(end).hour(23).minute(59); // A bit hardcode-y
  const calendarStart = start.clone().subtract(start.day(), 'days');
  const calendarEnd = end.clone().add(6 - end.day(), 'days');
  const duration = moment.duration(calendarEnd.diff(calendarStart));
  const dateArray = [];
  const monthRowIdx = [];
  let startIdx = 0;

  for (let i = 0; i < Math.ceil(duration.asWeeks()); ++i) {
    const week = [];
    for (let j = 0; j < 7; ++j) {
      const date = calendarStart.clone().add(i * 7 + j, 'd');
      if (date.date() === 1) startIdx = i; // New month
      // End of month render conditions
      if (
        (date.date() === date.daysInMonth() &&
          date.isBetween(cmpStart, cmpEnd, null, '[]')) ||
        date.format('YYYY-MM-DD') === cmpEnd.format('YYYY-MM-DD')
      )
        monthRowIdx.push({
          month: toLocaleYearMonthString(date, locale),
          start: startIdx,
          end: i + 1,
        });
      const VALID = date.isBetween(cmpStart, cmpEnd, null, '[]');
      week.push({
        date: date.format('YYYY-MM-DD'),
        valid: VALID,
        slotIdxs: VALID ? ASSET.getDateSlotIds(date) : null,
      });
    }

    dateArray.push(week);
  }

  return [dateArray, monthRowIdx];
}

function Calendar(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const CODE = useContext(LangCodeContext);
  const [DATE_ARRAY, MONTH_RENDER] = momentArray(props.asset, CODE);
  // Default view at the current month
  const startMonthIdx = MONTH_RENDER.findIndex(
    (monthObj) => monthObj.month === toLocaleYearMonthString(moment(), CODE),
  );
  const [monthIdx, setMonthIdx] = useState(
    startMonthIdx === -1 ? 0 : startMonthIdx,
  );
  const [showMore, setMore] = useState(false);
  return (
    <div className={classes.root}>
      <Box className={classes.title}>
        <Typography variant="h5" style={{ fontWeight: 400, lineHeight: 2 }}>
          {t('avail')}
        </Typography>
        <div
          style={{
            display: 'flex',
            width: '300px',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            onClick={() => setMonthIdx((prevState) => prevState - 1)}
            disabled={monthIdx === 0}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h6" style={{ fontWeight: 400, lineHeight: 2.4 }}>
            {MONTH_RENDER[monthIdx].month}
          </Typography>
          <IconButton
            onClick={() => setMonthIdx((prevState) => prevState + 1)}
            disabled={monthIdx === MONTH_RENDER.length - 1}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </div>
        <Typography variant="h5" style={{ visibility: 'hidden' }}>
          {t('avail')}
        </Typography>
      </Box>
      <CalendarHeadings week={DATE_ARRAY[0]} />
      {DATE_ARRAY.slice(
        MONTH_RENDER[monthIdx].start,
        showMore
          ? MONTH_RENDER[monthIdx].end
          : Math.min(
              MONTH_RENDER[monthIdx].end,
              MONTH_RENDER[monthIdx].start + 3,
            ),
      ).map((ROW, i) => {
        return (
          <Grid key={`R-${i}`} container spacing={0} className={classes.row}>
            {ROW.map((ELE, j) => {
              return (
                <CalendarItem
                  key={`ELE-${i}-${j}`}
                  data={ELE}
                  repIdx={props.idx}
                  edit={props.edit}
                  changeVeto={props.changeVeto}
                  vetoed={_.intersection(ELE.slotIdxs, props.veto)}
                  appointments={props.appointments}
                  asset={props.asset}
                />
              );
            })}
          </Grid>
        );
      })}
      {MONTH_RENDER[monthIdx].end - MONTH_RENDER[monthIdx].start > 3 && (
        <Box className={classes.center}>
          <Button
            variant="text"
            color="primary"
            onClick={() => setMore(!showMore)}
          >
            {showMore ? t('showLess') : t('showMore')}
          </Button>
        </Box>
      )}
    </div>
  );
}

export default Calendar;
