import moment from 'moment';
import 'moment/min/locales';
import 'moment-timezone';
import { LANG } from './i18n';

const userLangCode =
  window.localStorage.getItem('locale') ||
  navigator.language ||
  navigator.userLanguage;

export const timeZone = moment.tz.guess();

export function toLocaleDateString(date, loc = userLangCode) {
  return moment(date).locale(loc).tz(timeZone).format('LL');
}

export function toLocaleTimeString(date, loc = userLangCode) {
  return moment(date).locale(loc).tz(timeZone).format('LT');
}

export function toLocaleDayString(date, loc = userLangCode) {
  return moment(date).locale(loc).format('ddd');
}

export function toLocaleDayStringShort(date, loc = userLangCode) {
  return moment(date).locale(loc).format('dd');
}

export function toLocaleMonthString(date, loc = userLangCode) {
  return moment(date).locale(loc).format('MMMM');
}

export function toLocaleYearMonthString(date, loc = userLangCode) {
  return moment(date).locale(loc).format('MMMM YYYY');
}

export function toLocaleDayMonthYearString(date, loc = userLangCode) {
  return moment(date).locale(loc).format('DD/MM/YYYY');
}

export function toLocale12HourTimeString(date, loc = userLangCode) {
  return moment(date).locale(loc).tz(timeZone).format('HH:mm A');
}

export function getRelativeTimeString(date, loc = userLangCode) {
  return moment(date).locale(loc).fromNow();
}

export function getTzString() {
  return timeZone;
}

export function getTimeZoneString() {
  return `UTC ${moment().format('Z')}`;
}

export function calculateNumSlots(s, e, slotLength) {
  // S and e are MOMENT objects
  let minutes = e.hour() * 60 + e.minute() - s.hour() * 60 - s.minute();
  if (e.hour() < s.hour())
    minutes = (e.hour() + 24) * 60 + e.minute() - s.hour() * 60 - s.minute();
  return Math.floor(minutes / slotLength);
}

function parseUserLocale(userLangCode) {
  const userCode = userLangCode.split('-')[0];
  switch (userCode) {
    case 'en':
      return userCode;
    case 'ja':
      return userCode;
    default:
      return userLangCode;
  }
}

export function langIndex(allowedSet = LANG) {
  const userLangCode =
    window.localStorage.getItem('locale') ||
    navigator.language ||
    navigator.userLanguage;
  const userCode = parseUserLocale(userLangCode);
  const idx = allowedSet.findIndex((option) => option.code === userCode);
  return idx === -1 ? 0 : idx;
}

export function helpWidget() {
  /* eslint-disable no-undef */
  if (!window?.$zopim) return;
  $zopim(() => {
    $zopim.livechat.setName('');
    $zopim.livechat.window.show();
  });
}

export function timeZero(momentObj = moment()) {
  return momentObj.clone().hour(0).minute(0).second(0).millisecond(0);
}

// Function utcOffset(zone) {
//   return moment().tz(zone).utcOffset();
// }

// Const uniqueUTC = [...new Set(moment.tz.names().map(zone => utcOffset(zone)))];
// const utcOffsetArr = uniqueUTC.sort((a, b) => {
//   if (a < b) return -1;
//   if (a > b) return 1;
//   return 0;
// });

export function defaultSequence(EVENT, offset) {
  const START =
    offset === null
      ? moment(EVENT?.busStart || EVENT.start)
      : moment(EVENT?.busStart || EVENT.start).utcOffset(offset);
  const END =
    offset === null
      ? moment(EVENT?.busEnd || EVENT.end)
      : moment(EVENT?.busEnd || EVENT.end).utcOffset(offset);

  const SLOT_DURATION = EVENT.slot_duration;
  const NUM_SLOTS = calculateNumSlots(START, END, SLOT_DURATION);
  const dateFromIndex = (idx) =>
    START.clone().add(idx * SLOT_DURATION, 'minutes');

  let startIdx = 0;
  let minimum = 3600; // Safe as number of minutes in a day is 24 * 60
  const indices = Array.from(
    {
      length: NUM_SLOTS,
    },
    (x, i) => i,
  );
  for (const idx of indices) {
    const slotStart = dateFromIndex(idx);
    if (slotStart.minute() + 60 * slotStart.hour() < minimum) {
      startIdx = idx;
      minimum = slotStart.minute() + 60 * slotStart.hour();
    }
  }

  const SEQ = indices.splice(startIdx, NUM_SLOTS - startIdx);
  Array.from(
    {
      length: startIdx,
    },
    (x, i) => i,
  ).forEach((val) => SEQ.push(val));
  return SEQ;
}

export function generateAsset(EVENT, offset = null) {
  const START =
    offset === null
      ? moment(EVENT?.busStart || EVENT.start)
      : moment(EVENT?.busStart || EVENT.start).utcOffset(offset);
  const END =
    offset === null
      ? moment(EVENT?.busEnd || EVENT.end)
      : moment(EVENT?.busEnd || EVENT.end).utcOffset(offset);

  const SLOT_DURATION = EVENT.slot_duration;
  const DEFAULT_SEQUENCE = defaultSequence(EVENT, offset);
  const FIRST_DAY_LENGTH = getDateSequence(START).length;

  function getDateSequence(DATE) {
    if (DEFAULT_SEQUENCE[0] === 0) return DEFAULT_SEQUENCE;
    const zeroIndex = DEFAULT_SEQUENCE.indexOf(0);
    if (DATE.format('YYYY-MM-DD') === START.format('YYYY-MM-DD'))
      return DEFAULT_SEQUENCE.slice(zeroIndex);
    if (DATE.format('YYYY-MM-DD') === END.format('YYYY-MM-DD'))
      return DEFAULT_SEQUENCE.slice(0, zeroIndex);
    return DEFAULT_SEQUENCE;
  }

  function getDateSlotIds(DATE) {
    const duration = moment.duration(DATE.diff(START));
    const DAYS = Math.ceil(duration.asDays());
    const startId = (DAYS) => {
      if (DAYS === 0) return 0;
      return FIRST_DAY_LENGTH + (DAYS - 1) * DEFAULT_SEQUENCE.length;
    };

    return Array.from(
      {
        length: getDateSequence(DATE).length,
      },
      (x, i) => startId(DAYS) + i,
    );
  }

  function dateIndex(DATE, idx) {
    const time = DATE.clone().add(idx * SLOT_DURATION, 'minutes');
    return DATE.clone().hour(time.hour()).minute(time.minute()).second(0);
  }

  function dateFromSlotId(ID) {
    const calcDays = (id) => {
      if (id < FIRST_DAY_LENGTH) return [0, id];
      let days = 1;
      id -= FIRST_DAY_LENGTH - 1;
      while (id > DEFAULT_SEQUENCE.length) {
        days += 1;
        id -= DEFAULT_SEQUENCE.length;
      }

      return [days, id - 1];
    };

    const [DAYS, INDEX] = calcDays(ID);
    // Const duration = moment.duration(END.diff(START));
    const DATE_ONLY = START.clone().add(DAYS, 'd');
    const SEQ = getDateSequence(DATE_ONLY);
    return dateIndex(DATE_ONLY, SEQ[INDEX]);
  }

  return {
    START,
    END,
    OFFSET: offset,
    DEFAULT_SEQUENCE,
    FIRST_DAY_LENGTH,
    SLOT_DURATION,
    getDateSequence,
    getDateSlotIds,
    dateFromSlotId,
    dateIndex,
  };
}

export function offsetString(offset) {
  return `UTC ${moment().utcOffset(offset).format('Z')}`;
}

const reedEventIds = [
  'Z6A5ypEUqdp8VTeCw5KI',
  'YWYuTpF6PrGwLeySj754',
  'bUfFfLVMpQ6ykd2xj3pz',
  '2yzBCr3R3viPDbknQE61',
  'tmku8Jycb1wTWlvrslRQ',
  'PYk6OkdJaAKi7DUnvedX',
  'zjmiVzw4OsbLIrABdXhr',
  '6dn6B68mSNt4bB9BiJZB',
  'pEyuBufBT1V0lM1GxWUM',
  '3gYmucZi8E0HAcDDc2AE',
  'O0YcT7BbndpsOw0H10HO',
  'uqVy6Bab14qpOo8n5Alk',
  'SErWVQpv1fSCONIZJ0L0',
  'vSBgF6yoQ9br0v1JQd8C',
  'UsVfFNZtg34ERH0s0gEU',
  '8xzxP6hNrycX4Vzq1l0Z',
  'Wr7sL3CyFiL1uz9nF18a',
  'pZeSafq4CBRF35CVxzTl',
  'ewvhUBlEeLAvjty8NXJg',
  'zjmiVzw4OsbLIrABdXhr',
  'wu9kzpjmfjsW3VZyZCp8',
  'nyJjmPu0qaj5XesE4dpb',
  'zSG1eC7I9p1EIxm7hd3Y',
  'l76GFZAdRepaXK7BAtWV',
  'pzYkFyUurp9V2Mj58eGT',
  'EwjVH2Bi5sdH3fVcLlWZ',
  'oTg6j4K9muxVZtLRjIta',
  'pZOMxP2sP2hUzkLSL9iu',
  'aTfqT2Jh2At3WDX96Fg2',
  'tskUxYFFSmEBOEcHXXU7',
  'enehI0hN1ZQefVRJNLQX',
  'vyObribBDU79t3v7VTDs',
  'uz4BsVJwH01EF3Md3QVk',
  '1XYTrfcte8L2XXEbxwtT',
  'QQLhCRwIaJ5cDat91enL',
  'YGeHDauwK5CeaMItsmT6',
  'wvYXmQGbqwtN0Hsj7RwQ',
  'bJoAyqpmmgGrUamlsttX',
  'XZmdoKbwRJrjkAabmcKD',
  'ialAN1xsXM7zPyjOhP0Q',
  '1kd12XOWwNOlaIl3zqTA',
];

export function custom(EVENT) {
  if (EVENT !== null && reedEventIds.includes(EVENT.id)) return true;
  return false;
}

export const filterLang = (id) => {
  if (!id) return LANG;
  switch (id) {
    case 'SbxkDbKRyaxvW1BFwLrH': {
      const allowedLocales = ['en'];
      return LANG.filter((language) => allowedLocales.includes(language.code));
    }

    case 'OayqUDQgmET1ELBvzknL': {
      const allowedLocales = ['en', 'zh-TW'];
      return LANG.filter((language) => allowedLocales.includes(language.code));
    }

    default:
      return LANG;
  }
};
