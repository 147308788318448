// React Stuffs
import React, { useState } from 'react';
import i18n from 'i18next';
// Material UI
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// Matierial UI Icons
import TranslateIcon from '@material-ui/icons/Translate';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTranslation } from 'react-i18next';

// Source Files
import Background from '../assets/undraw_warning_cyit.svg';
import { helpWidget, langIndex } from '../Functions';
import { LANG } from '../i18n';

const useStyles = makeStyles((theme) => {
  return {
    background: {
      backgroundImage: `url(${Background})`,
      width: '100vw',
      height: '100vh',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    container: {
      marginTop: theme.spacing(12),
      textAlign: 'center',
    },
    text: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
    },
    footer: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      height: theme.spacing(3),
      backgroundColor: 'white',
    },
    link: {
      marginLeft: theme.spacing(1),
      fontWeight: 400,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    icon: {
      fill: theme.palette.primary.main,
    },
    noBold: {
      fontWeight: 400,
    },
  };
});

function Bar({ selectedIndex, selectLanguage }) {
  const classes = useStyles();
  const desktop = useMediaQuery('(min-width:965px)');
  const [anchorEl, setAnchorEl] = useState(null);
  // Effects
  // Functions
  const handleClickListItem = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuItemClick = (idx) => {
    selectLanguage(idx);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" style={{ backgroundColor: 'white' }}>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <div />
        <div>
          <IconButton color="inherit" onClick={helpWidget}>
            <HelpOutlineIcon className={classes.icon} />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={handleClickListItem}
            disabled={desktop}
          >
            <TranslateIcon className={classes.icon} />
          </IconButton>
          {desktop && (
            <Select
              value={LANG[selectedIndex].name}
              disableUnderline
              style={{ color: 'black' }}
            >
              {LANG.map((option, idx) => (
                <MenuItem
                  key={`SelectItem-${option.name}`}
                  value={option.name}
                  onClick={() => selectLanguage(idx)}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </Toolbar>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {LANG.map((option, index) => (
          <MenuItem
            key={`MenuItem-${option.name}`}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
}

function Error() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(langIndex());
  const CODE = LANG[selectedIndex].code;
  // eslint-disable-next-line no-undef
  if (typeof ze === 'function') zE('webWidget', 'setLocale', CODE);

  const selectLanguage = (idx) => {
    setSelectedIndex(idx);
    const lng = LANG[idx].code;
    window.localStorage.setItem('locale', lng);
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <Bar selectedIndex={selectedIndex} selectLanguage={selectLanguage} />
      <Container component="main" maxWidth="sm" className={classes.container}>
        <img src={Background} width="90%" />
        <div className={classes.text}>
          <Typography variant="h4">{t('shortOops')}</Typography>
          <Typography
            variant="h6"
            className={classes.noBold}
            style={{ marginTop: '0.5rem' }}
          >
            {t('noPage')}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '0.5rem',
            }}
          >
            <Typography variant="h6" className={classes.link}>
              <Link onClick={helpWidget} underline="none">
                {t('needHelp')}
              </Link>
            </Typography>
          </div>
        </div>
      </Container>
      <div className={classes.footer}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body2" className={classes.link}>
            <Link href={`https://eventx.io/${CODE}`} underline="none">
              {t('virtualEvent')}
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Error;
