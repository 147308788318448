import i18n from 'i18next';
import React, { useState, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  IconButton,
  Typography,
  Select,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Translate as TranslateIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { EventContext, LangCodeContext } from '../Context';
import { helpWidget, filterLang, langIndex } from '../../Functions';

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 500,
  },
  barLeft: {
    width: '250px',
    textAlign: 'left',
  },
  barRight: {
    width: '250px',
    textAlign: 'right',
  },
}));

function NavBar() {
  const event = useContext(EventContext);

  const desktop = useMediaQuery('(min-width:965px)');
  const classes = useStyles();

  const { setCode } = useContext(LangCodeContext);

  const filteredLang = filterLang(event?.id);
  const [selectedIndex, setSelectedIndex] = useState(langIndex(filteredLang));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setLanguage = (idx) => {
    setSelectedIndex(idx);
    const lng = filteredLang[idx].code;
    window.localStorage.setItem('locale', lng);
    setCode(lng);
    i18n.changeLanguage(lng);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    setLanguage(index);
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <div className={desktop ? classes.barLeft : null}>
          <IconButton color="inherit" style={{ visibility: 'hidden' }}>
            <TranslateIcon />
          </IconButton>
        </div>
        <Typography variant="h5" className={classes.title}>
          {event.name}
        </Typography>
        <div className={desktop ? classes.barRight : null}>
          {desktop && (
            <IconButton color="inherit" onClick={helpWidget}>
              <HelpOutlineIcon style={{ fill: 'white' }} />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            onClick={handleClickListItem}
            disabled={desktop}
          >
            <TranslateIcon style={{ fill: 'white' }} />
          </IconButton>
          {desktop && (
            <Select
              value={filteredLang[selectedIndex].name}
              disableUnderline
              style={{ color: 'white' }}
            >
              {filteredLang.map((option, idx) => (
                <MenuItem
                  key={option.name}
                  value={option.name}
                  onClick={() => setLanguage(idx)}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </Toolbar>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {filteredLang.map((option, index) => (
          <MenuItem
            key={option.name}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
}

export default NavBar;
