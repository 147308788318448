import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'moment-timezone';
import { ics } from 'calendar-link';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

function Download(props) {
  const { t } = useTranslation();
  const APP_ID = props.match.params.id;
  const [app, setApp] = useState(null);
  const [rep, setRep] = useState(null);
  const [ex, setEx] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const appRes = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/get/appointment/${APP_ID}`,
        )
          .then((val) => val.json())
          .catch((e) => console.log(e.message));
        if (appRes.success) setApp(appRes.data);
        else alert(appRes.message);
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [APP_ID]);

  useEffect(() => {
    (async () => {
      try {
        if (app !== null) {
          const repRes = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/get_all/representative/${app.ex_id}`,
          )
            .then((val) => val.json())
            .catch((e) => console.log(e.message));
          if (repRes.success) {
            setRep(repRes.data.filter((rep) => rep.id === app.rep_id)[0]);
            const exRes = await fetch(
              `${process.env.REACT_APP_API_ENDPOINT}/get/exhibitor/${app.ex_id}`,
            )
              .then((val) => {
                return val.json();
              })
              .catch((e) => console.log(e.message));
            if (exRes.success) setEx(exRes.data);
            else alert(exRes.message);
          } else alert(repRes.message);
        }
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [app]);

  useEffect(() => {
    if (app !== null && rep !== null && ex !== null) {
      const event = {
        title: t('icsTitle', { representative: rep.name, exhibitor: ex.name }),
        description: app.meetingLink ?? rep.link,
        start: app.start,
        end: app.end,
      };
      saveAs(ics(event), `${event.title}.ics`);
    }
  }, [app, rep, ex, t]);

  return app !== null && rep !== null && ex !== null ? (
    <div></div>
  ) : (
    <div className="abs-center">
      <CircularProgress />
    </div>
  );
}

export default Download;
