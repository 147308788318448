import { createContext } from 'react';

export const EventContext = createContext(null);
export const ExhibitorContext = createContext(null);
export const RepContext = createContext(null);
export const AppContext = createContext(null);
export const LangCodeContext = createContext(null);
export const AssetContext = createContext(null);
export const CustomContext = createContext(null);
export const AttendeeContext = createContext(null);
export const HostNameContext = createContext(null);
