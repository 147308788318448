export const timeZones = [
  {
    string: 'UTC -12:00',
    offset: -720,
  },
  {
    string: 'UTC -11:00',
    offset: -660,
  },
  {
    string: 'UTC -10:00',
    offset: -600,
  },
  {
    string: 'UTC -09:30',
    offset: -570,
  },
  {
    string: 'UTC -09:00',
    offset: -540,
  },
  {
    string: 'UTC -08:00',
    offset: -480,
  },
  {
    string: 'UTC -07:00',
    offset: -420,
  },
  {
    string: 'UTC -06:00',
    offset: -360,
  },
  {
    string: 'UTC -05:00',
    offset: -300,
  },
  {
    string: 'UTC -04:00',
    offset: -240,
  },
  {
    string: 'UTC -03:00',
    offset: -180,
  },
  {
    string: 'UTC -02:30',
    offset: -150,
  },
  {
    string: 'UTC -02:00',
    offset: -120,
  },
  {
    string: 'UTC -01:00',
    offset: -60,
  },
  {
    string: 'UTC +00:00',
    offset: 0,
  },
  {
    string: 'UTC +01:00',
    offset: 60,
  },
  {
    string: 'UTC +02:00',
    offset: 120,
  },
  {
    string: 'UTC +03:00',
    offset: 180,
  },
  {
    string: 'UTC +04:00',
    offset: 240,
  },
  {
    string: 'UTC +04:30',
    offset: 270,
  },
  {
    string: 'UTC +05:00',
    offset: 300,
  },
  {
    string: 'UTC +05:30',
    offset: 330,
  },
  {
    string: 'UTC +05:45',
    offset: 345,
  },
  {
    string: 'UTC +06:00',
    offset: 360,
  },
  {
    string: 'UTC +06:30',
    offset: 390,
  },
  {
    string: 'UTC +07:00',
    offset: 420,
  },
  {
    string: 'UTC +08:00',
    offset: 480,
  },
  {
    string: 'UTC +08:45',
    offset: 525,
  },
  {
    string: 'UTC +09:00',
    offset: 540,
  },
  {
    string: 'UTC +09:30',
    offset: 570,
  },
  {
    string: 'UTC +10:00',
    offset: 600,
  },
  {
    string: 'UTC +10:30',
    offset: 630,
  },
  {
    string: 'UTC +11:00',
    offset: 660,
  },
  {
    string: 'UTC +12:00',
    offset: 720,
  },
  {
    string: 'UTC +12:45',
    offset: 765,
  },
  {
    string: 'UTC +13:00',
    offset: 780,
  },
  {
    string: 'UTC +14:00',
    offset: 840,
  },
];
