import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';
import Home from './components/Home';
import Exhibitor from './components/Exhibitor';
import Appointment from './components/Appointment';
import AppointmentDetails from './components/AppointmentDetails';
import Cancel from './components/Cancel/Cancel';
import Download from './components/Download';
import Error from './components/Error';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_THEME_MAIN,
      light: process.env.REACT_APP_THEME_LIGHT,
    },
    secondary: {
      main: '#000000DE',
      light: '#DBDEE2',
    },
    error: {
      main: '#D32F2F',
    },
  },
  typography: {
    button: {
      fontSize: '1rem',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none',
        fontWeight: 'bold',
      },
    },
    MuiInputLabel: {
      root: {
        backgroundColor: 'white',
      },
    },
    MuiSnackbar: {
      root: {
        maxWidth: '600px',
      },
    },
    MuiSnackbarContent: {
      action: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        justifyContent: 'center',
      },
      message: {
        width: '100%',
        fontSize: '1rem',
        textAlign: 'center',
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/exhibitor/:id" component={Exhibitor} />
              <Route path="/appointment/:id" component={Appointment} />
              <Route
                path="/appointment-details/:id"
                component={AppointmentDetails}
              />
              <Route path="/cancel/:id" component={Cancel} />
              <Route path="/ical/:id" component={Download} />
              <Route path="/error" component={Error} />
              <Route path="*" component={Error} />
            </Switch>
          </Router>
        </I18nextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
