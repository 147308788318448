import React, { useContext, useEffect, useState } from 'react';
import { EventContext, CustomContext } from '../Context';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { timeZones } from '../../assets/timezones';
import moment from 'moment';
import { offsetString } from '../../Functions';
import isURL from 'validator/es/lib/isURL';
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  form: {
    width: '500px',
  },
  label: {
    transform: 'translate(14px, 9px) scale(1)',
  },
  inputRoot: {
    padding: '6px 14px 7px 14px',
  },
  multiline: {
    padding: 0,
  },
  fieldEdit: {
    marginTop: '3px',
  },
  left: {
    width: '165px',
  },
  right: {
    width: '500px',
  },
  container: {
    display: 'flex',
    marginBottom: '0.5rem',
  },
  popOver: {
    padding: theme.spacing(2),
  },
  popOverRight: {
    width: '544px',
  },
  warning: {
    display: 'flex',
    color: theme.palette.warning.dark,
    alignItems: 'center',
  },
}));

// eslint-disable-next-line complexity
function Form(props) {
  const { t } = useTranslation();
  const EVENT = useContext(EventContext);
  const CUSTOM = useContext(CustomContext);
  const classes = useStyles();
  const theme = useTheme();
  const afterEvent = moment().valueOf() > EVENT.start;
  const newRep = props.details?.local || false;
  const showText = !newRep && props.edit && afterEvent;
  const { name, email, link, desc } = props.details;
  const [details, setDetails] = useState({
    name,
    email,
    link,
    desc,
  });

  useEffect(() => {
    setDetails({
      name,
      email,
      link,
      desc,
    });
  }, [desc, email, link, name, props.deletedIdx]);

  const [id, setId] = useState(null);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setDetails((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    const obj = {
      ...details,
      [name]: value,
    };
    passInTheFuture(obj);
  };

  function passInTheFuture(obj) {
    if (id !== null) {
      clearTimeout(id);
    }

    const idCode = setTimeout(() => {
      props.handleInput(obj, props.idx);
    }, 250);
    setId(idCode);
  }

  function handleOffsetChange(e) {
    props.passOffset(e.target.value, props.idx);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 144,
      },
    },
  };

  const { shouldUseDailyCo } = useFeatureFlag();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.left}>
          <Typography
            variant="body1"
            className={props.edit ? classes.fieldEdit : undefined}
          >
            <span style={{ color: theme.palette.error.main }}>* </span>
            {t('name')}
          </Typography>
        </div>
        <div className={classes.right}>
          {props.edit ? (
            <TextField
              className={classes.form}
              variant="outlined"
              margin="none"
              error={props.valid[0]}
              helperText={props.valid[0] ? t('required') : undefined}
              id="name"
              name="name"
              InputLabelProps={{ classes: { outlined: classes.label } }}
              InputProps={{ classes: { input: classes.inputRoot } }}
              value={details.name}
              onInput={handleInput}
            />
          ) : (
            <Typography variant="body1">{props.details.name}</Typography>
          )}
        </div>
      </div>
      <div
        className={classes.container}
        style={{ marginBottom: showText ? 0 : '0.5rem' }}
      >
        <div className={classes.left}>
          <Typography
            variant="body1"
            className={props.edit ? classes.fieldEdit : undefined}
          >
            <span style={{ color: theme.palette.error.main }}>* </span>
            {t('email')}
          </Typography>
        </div>
        <div className={classes.right}>
          {props.edit ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                className={classes.form}
                variant="outlined"
                margin="none"
                error={props.valid[1] || props.valid[2]}
                helperText={
                  props.valid[1]
                    ? t('required')
                    : props.valid[2]
                    ? t('invEmail')
                    : undefined
                }
                id="email"
                name="email"
                InputLabelProps={{ classes: { outlined: classes.label } }}
                InputProps={{ classes: { input: classes.inputRoot } }}
                value={details.email}
                onInput={handleInput}
              />
            </div>
          ) : (
            <Typography variant="body1">{props.details.email}</Typography>
          )}
        </div>
      </div>
      {showText && (
        <div className={classes.container}>
          <div className={classes.left}></div>
          <div className={classes.right}>
            <div className={classes.warning}>
              <WarningIcon style={{ height: '1rem' }} />
              <Typography variant="body2">{t('meetUpEmailWarning')}</Typography>
            </div>
          </div>
        </div>
      )}
      {!shouldUseDailyCo && (
        <div
          className={classes.container}
          style={{ marginBottom: showText ? 0 : '0.5rem' }}
        >
          <div className={classes.left} style={{ display: 'flex' }}>
            <Typography
              variant="body1"
              className={props.edit ? classes.fieldEdit : undefined}
            >
              {!CUSTOM && (
                <span style={{ color: theme.palette.error.main }}>* </span>
              )}
              {CUSTOM ? t('link') : t('meetUp')}
            </Typography>
          </div>
          <div className={classes.right}>
            {props.edit ? (
              <TextField
                className={classes.form}
                variant="outlined"
                margin="none"
                id="link"
                name="link"
                error={props.valid[3]}
                helperText={
                  props.valid[3]
                    ? CUSTOM
                      ? t('invLink')
                      : t('required')
                    : undefined
                }
                InputLabelProps={{ classes: { outlined: classes.label } }}
                InputProps={{ classes: { input: classes.inputRoot } }}
                value={details.link}
                onInput={handleInput}
              />
            ) : (
              <Typography variant="body1">
                {isURL(props.details.link) ? (
                  <Link href={props.details.link}>{props.details.link}</Link>
                ) : (
                  props.details.link
                )}
              </Typography>
            )}
          </div>
        </div>
      )}
      {showText && (
        <div className={classes.container}>
          <div className={classes.left}></div>
          <div className={classes.right}>
            <div className={classes.warning}>
              <WarningIcon style={{ height: '1rem' }} />
              <Typography variant="body2">
                {t('meetUpDetailWarning')}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.left}>
          <Typography
            variant="body1"
            className={props.edit ? classes.fieldEdit : undefined}
          >
            {t('intro')}
          </Typography>
        </div>
        <div className={classes.right}>
          {props.edit ? (
            <TextField
              style={{ padding: 0 }}
              className={classes.form}
              variant="outlined"
              margin="none"
              id="desc"
              name="desc"
              multiline
              rows={4}
              InputLabelProps={{ classes: { outlined: classes.label } }}
              InputProps={{
                classes: {
                  input: classes.inputRoot,
                  multiline: classes.multiline,
                },
              }}
              value={details.desc}
              onInput={handleInput}
            />
          ) : (
            <Typography variant="body1">{props.details.desc}</Typography>
          )}
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.left}>
          <Typography
            variant="body1"
            className={props.edit ? classes.fieldEdit : undefined}
          >
            <span style={{ color: theme.palette.error.main }}>* </span>
            {t('tz')}
          </Typography>
        </div>
        <div className={classes.right}>
          {props.edit ? (
            <Select
              value={props.details.offset}
              variant="outlined"
              style={{ height: '32px', width: '144px' }}
              MenuProps={MenuProps}
              onChange={handleOffsetChange}
            >
              {timeZones.map((zone, idx) => (
                <MenuItem key={`TimeZone-${idx}`} value={zone.offset}>
                  {zone.string}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Typography variant="body1">
              {offsetString(props.details.offset)}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default Form;
