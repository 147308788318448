import { event } from './event';
import { appointment } from './appointment';
import { exhibitor } from './exhibitor';
import { representative } from './representative';

export const api = {
  event,
  appointment,
  exhibitor,
  representative,
};
