// React Stuffs
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  AttendeeContext,
  ExhibitorContext,
  EventContext,
  RepContext,
  LangCodeContext,
  AssetContext,
  HostNameContext,
  CustomContext,
} from '../Context';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
// Matierial UI Icons
// Source Files
import queryString from 'query-string';
import { Redirect, useLocation } from 'react-router-dom';
import Error from '../Error';
import { langIndex, generateAsset, custom, filterLang } from '../../Functions';
import { api } from '../../services/api';

import RepPage from './RepPage';
import SelectDateSlot from './SelectDateSlot';
import Form from './Form';
import Confirmation from './Confirmation';

function Appointment(props) {
  // Constants and Context
  const EX_ID = props.match.params.id;
  const location = useLocation();
  const encodedAttendee =
    queryString.parse(location.search)?.attendee ||
    window.localStorage.getItem('attendee');

  const hostName = queryString.parse(location.search)?.hostName || '';

  // States
  const [EX, setEx] = useState(null);
  const [EVENT, setEvent] = useState(null);
  const [REPS, setReps] = useState(null);
  const [ATTENDEE, setAttendee] = useState(null);
  const [error, setError] = useState(false);
  const [langCode, setLangCode] = useState(null);
  // eslint-disable-next-line no-undef
  if (typeof ze === 'function') zE('webWidget', 'setLocale', langCode);

  // Effects
  useEffect(() => {
    (async () => {
      try {
        const repRes = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/get_all/representative/${EX_ID}`,
        )
          .then((val) => val.json())
          .catch((e) => console.log(e.message));
        if (repRes.success) setReps(repRes.data);
        else throw new Error(repRes.message);
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [EX_ID]);

  useEffect(() => {
    (async () => {
      try {
        const exRes = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/get/exhibitor/${EX_ID}`,
        )
          .then((val) => {
            return val.json();
          })
          .catch((e) => console.log(e.message));
        if (exRes.success) setEx(exRes.data);
        else throw new Error(exRes.message);
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [EX_ID]);

  useEffect(() => {
    (async () => {
      try {
        if (EX !== null) {
          const evRes = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/get/event/${EX.event_id}`,
          )
            .then((val) => val.json())
            .catch((e) => console.log(e.message));
          if (evRes.success) {
            setEvent(evRes.data);
            const { id } = evRes.data;
            const filteredLang = filterLang(id);
            setLangCode(filteredLang[langIndex(filteredLang)].code);
          } else throw new Error(evRes.message);
        }
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [EX]);

  useEffect(() => {
    if (hostName) {
      window.sessionStorage.setItem(`${EX_ID}_HostName`, hostName);
    } else if (EVENT?.eventDomain) {
      window.sessionStorage.setItem(
        `${EX_ID}_HostName`,
        EVENT?.eventDomain.toLowerCase().split('.').join('_'),
      );
    }

    (async () => {
      if (EVENT?.eventDomain && encodedAttendee) {
        try {
          window.localStorage.setItem('attendee', encodedAttendee);
          const attendeeData = JSON.parse(
            decodeURIComponent(escape(atob(encodedAttendee))),
          );
          setAttendee(attendeeData);
        } catch (e) {
          console.log(e.message);
        }
      }
    })();
  }, [EVENT, EX_ID, encodedAttendee, hostName]);

  useEffect(() => {
    if (ATTENDEE?.email && ATTENDEE?.ex_id && ATTENDEE?.rep_id) {
      (async () => {
        try {
          const res = await api.representative.getRepresentatives({
            ex_id: ATTENDEE?.ex_id,
          });
          const attendeeRep = res.find(
            ({ id, email }) =>
              id === ATTENDEE.rep_id && email === ATTENDEE?.email,
          );
          if (attendeeRep) {
            ATTENDEE.confirmed = attendeeRep.confirmed;
            setAttendee(ATTENDEE);
          }
        } catch (e) {
          console.log(e.message);
        }
      })();
    }
  }, [ATTENDEE]);

  // Functions
  if (error) return <Redirect to={`/error`} />;

  if (
    window.location.pathname === `/appointment/${EX_ID}` ||
    window.location.pathname === `/appointment/${EX_ID}/`
  )
    return <Redirect to={`/appointment/${EX_ID}/all`} />;

  return EVENT !== null && EX !== null && REPS !== null ? (
    <EventContext.Provider value={EVENT}>
      <ExhibitorContext.Provider value={EX}>
        <RepContext.Provider value={REPS}>
          <AttendeeContext.Provider value={ATTENDEE}>
            <LangCodeContext.Provider
              value={{ code: langCode, setCode: (code) => setLangCode(code) }}
            >
              <AssetContext.Provider value={generateAsset(EVENT)}>
                <CustomContext.Provider value={custom(EVENT)}>
                  <HostNameContext.Provider
                    value={window.sessionStorage.getItem(`${EX_ID}_HostName`)}
                  >
                    <Router>
                      <Switch>
                        <Route
                          path={`/appointment/${EX_ID}/all`}
                          exact
                          render={(props) => <RepPage {...props} />}
                        />
                        <Route
                          path={`/appointment/${EX_ID}/R:repIdx/select`}
                          exact
                          render={(props) => <SelectDateSlot {...props} />}
                        />
                        <Route
                          path={`/appointment/${EX_ID}/R:repIdx/form`}
                          exact
                          render={(props) => <Form {...props} />}
                        />
                        <Route
                          path={`/appointment/${EX_ID}/confirm`}
                          exact
                          render={(props) => <Confirmation {...props} />}
                        />
                        <Route path="*" exact component={Error} />
                      </Switch>
                    </Router>
                  </HostNameContext.Provider>
                </CustomContext.Provider>
              </AssetContext.Provider>
            </LangCodeContext.Provider>
          </AttendeeContext.Provider>
        </RepContext.Provider>
      </ExhibitorContext.Provider>
    </EventContext.Provider>
  ) : (
    <div className="abs-center">
      <CircularProgress />
    </div>
  );
}

export default Appointment;
