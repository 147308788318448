// React Stuffs
import React, { useContext, useEffect, useState } from 'react';
import { ExhibitorContext, RepContext } from '../Context';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

// Matierial UI Icons
import PeopleIcon from '@material-ui/icons/People';

// Source Files
import TopBar from './TopBar';
import RepCard from './RepCard';

const useStyles = makeStyles((theme) => ({
  canvas: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '4rem',
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  exName: {
    marginBottom: theme.spacing(2),
  },
  oops: {
    textAlign: 'center',
    fontWeight: 400,
  },
}));

let defaultRedirect = false;

function RepPage(props) {
  const { t } = useTranslation();
  // Constants, Context, State
  const classes = useStyles();
  const EX = useContext(ExhibitorContext);
  const REPS = useContext(RepContext);
  const [direct, setDirect] = useState(false);
  // Effects
  useEffect(() => {
    if (REPS.length === 1 && !defaultRedirect) {
      setDirect(true);
      defaultRedirect = true;
    }
  }, [REPS.length]);

  // Functions

  if (direct) return <Redirect to={`/appointment/${EX.id}/R0/select`} />;

  return (
    <Container component="main" maxWidth="sm">
      <TopBar hide={true} goBack={() => {}} />
      <div className={classes.canvas}>
        <Avatar className={classes.avatar}>
          <PeopleIcon />
        </Avatar>
        <Typography variant="h5" className={classes.exName}>
          {EX.name}
        </Typography>
        {REPS.map((REP, idx) => {
          return (
            <RepCard key={`RepCard-${idx}`} rep={REP} idx={idx} {...props} />
          );
        })}
        {REPS.length === 0 && (
          <Typography variant="h6" className={classes.oops}>
            {t('oopsNoRep')}
          </Typography>
        )}
      </div>
    </Container>
  );
}

export default RepPage;
