import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CircularProgress } from '@material-ui/core';
import qs from 'query-string';

import NavBar from '../common/NavBar';
import { api } from '../../services/api';
import {
  EventContext,
  AppContext,
  RepContext,
  ExhibitorContext,
  LangCodeContext,
} from '../Context';
import { langIndex, filterLang } from '../../Functions';
import Details from './Details';

function Index() {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const APP_ID = params.id;

  const [langCode, setLangCode] = useState(null);

  const { data: app, refetch: appRefetch } = useQuery(
    ['get-appointment'],
    async () => api.appointment.getAppointment({ app_id: APP_ID }),
    { refetchOnWindowFocus: true },
  );

  const auth_token = useMemo(
    () =>
      qs.parse(location.search).auth_token ||
      (app && app.ex_id && window.localStorage.getItem(app.ex_id)),
    [app, location.search],
  );

  useEffect(() => {
    (async () => {
      try {
        if (app && app.ex_id) {
          await api.exhibitor.auth({ ex_id: app.ex_id, auth_token });
          window.localStorage.setItem(app.ex_id, auth_token);
          history.replace(location.pathname);
        }
      } catch {
        history.replace('/error');
      }
    })();
  }, [app, auth_token, history, location.pathname]);

  const { data: representatives } = useQuery(
    ['get-representatives'],
    async () => api.representative.getRepresentatives({ ex_id: app.ex_id }),
    {
      enabled: Boolean(app),
    },
  );
  const representative = representatives
    ? representatives.find((rep) => rep.id === app.rep_id)
    : {};

  const { data: event } = useQuery(
    ['get-event'],
    async () => api.event.getEvent({ event_id: app.event_id }),
    {
      onSuccess(res) {
        const filteredLang = filterLang(res.id);
        setLangCode(filteredLang[langIndex(filteredLang)].code);
      },
      enabled: Boolean(app),
    },
  );

  const { data: exhibitor } = useQuery(
    ['get-exhibitor'],
    async () => api.exhibitor.getExhibitor({ ex_id: app.ex_id }),
    {
      enabled: Boolean(app),
    },
  );

  // eslint-disable-next-line no-undef
  if (typeof ze === 'function') zE('webWidget', 'setLocale', langCode);

  if (!event || !app || !representative || !exhibitor)
    return (
      <div className="abs-center">
        <CircularProgress />
      </div>
    );

  return (
    <EventContext.Provider value={event}>
      <ExhibitorContext.Provider value={exhibitor}>
        <AppContext.Provider value={{ data: app, refetch: appRefetch }}>
          <RepContext.Provider value={representative}>
            <LangCodeContext.Provider
              value={{ code: langCode, setCode: (code) => setLangCode(code) }}
            >
              <NavBar />
              <Details />
            </LangCodeContext.Provider>
          </RepContext.Provider>
        </AppContext.Provider>
      </ExhibitorContext.Provider>
    </EventContext.Provider>
  );
}

export default Index;
