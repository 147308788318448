import rq from './request';

export const exhibitor = {
  async auth({ ex_id, auth_token }) {
    return rq.post(`authenticate/exhibitor`, {
      ex_id,
      auth_token,
    });
  },
  async getExhibitor({ ex_id }) {
    return rq.get(`/get/exhibitor/${ex_id}`);
  },
};
