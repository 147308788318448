import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import Calendar from './Calendar';
import Form from './Form';
import {
  ExhibitorContext,
  LangCodeContext,
  EventContext,
  CustomContext,
} from '../Context';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import isEmail from 'validator/es/lib/isEmail';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generateAsset } from '../../Functions';
import moment from 'moment';
import Snack from './Snack';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import isEmpty from 'validator/es/lib/isEmpty';
import isURL from 'validator/es/lib/isURL';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '1rem',
    padding: '0 0.5rem',
  },
  content: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  save: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    marginBottom: '1rem',
  },
  slot: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function EditDetailsDialog({ open, handleClose, applySave }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <DialogTitle>{t('editDetailsTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('editDetailsContent')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          {t('no')}
        </Button>
        <Button color="primary" onClick={applySave}>
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ReminderDialog({ open, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <DialogTitle>{t('remDialogTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('remDialogContent')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ConflictDialog({ open, handleClose, conflicts, asset }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const CODE = useContext(LangCodeContext);
  return (
    <Dialog open={open}>
      <DialogTitle>{t('saveFailed')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('appConfictMessage')}</DialogContentText>
        {conflicts.map((conflict, idx) => {
          const slotTime = asset.dateFromSlotId(conflict);
          return (
            <Paper
              key={`Conflict-${idx}`}
              className={classes.slot}
              elevation={2}
            >
              <Typography variant="body1">
                {`${slotTime.locale(CODE).format('LL')}`}
              </Typography>
              <Typography variant="body1" style={{ marginLeft: '1rem' }}>
                {`${slotTime.locale(CODE).format('LT')}`}
              </Typography>
            </Paper>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Representative(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const EVENT = useContext(EventContext);
  const CUSTOM = useContext(CustomContext);
  const ASSET = generateAsset(EVENT, props.data.offset);
  const EX = useContext(ExhibitorContext);
  const CODE = useContext(LangCodeContext);
  const [isProcessing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [last, setLast] = useState(false);
  const [snack, setSnack] = useState(false);
  const [changeDialog, setChangeDialog] = useState(false);
  const [reminderDialog, setReminderDialog] = useState(false);
  const [saveConflict, setSaveConflict] = useState(false);
  const [conflictApp, setConflictApp] = useState([]);
  // 0: empty name, 1: empty email, 2: invalid email, 3: empty link, 4: invalid link
  const [validation, setValidation] = useState([false, false, false, false]);
  const APPOINTMENT_IDS = props.data.appointments.map((app) => app.slot_id);
  const LAST_ID =
    APPOINTMENT_IDS.length > 0 ? Math.max(...APPOINTMENT_IDS) : null;

  const { shouldSuspendMultipleRepresentative, shouldUseDailyCo } =
    useFeatureFlag();

  function setEdit(bool) {
    props.setEdit(bool, props.idx);
  }

  function passConflictApp(conflicts) {
    conflicts.forEach((conflict) => {
      setConflictApp((prevState) => [...prevState, conflict]);
      props.changeApp(conflict, props.idx);
    });
    setSaveConflict(true);
  }

  function closeConflict() {
    setSaveConflict(false);
    setConflictApp((prevState) => prevState.filter(() => false));
  }

  let skipCheck = false;
  const handleSave = async () => {
    setProcessing(true);
    setEdit(false);
    if (
      !skipCheck &&
      (!props.booleanSaved.link || !props.booleanSaved.email) &&
      !(props.data?.local || false) &&
      moment().valueOf() > EVENT.start
    ) {
      setChangeDialog(true);
      return;
    }

    const valCheck = [
      isEmpty(props.data.name, { ignore_whitespace: true }),
      isEmpty(props.data.email, { ignore_whitespace: true }),
      !isEmail(props.data.email),
      CUSTOM
        ? isEmpty(props.data.link, { ignore_whitespace: true })
          ? false
          : !isURL(props.data.link)
        : shouldUseDailyCo
        ? false
        : isEmpty(props.data.link, { ignore_whitespace: true }),
    ];
    setValidation(valCheck);

    // Return if validation check failed
    if (valCheck.reduce((accum, bool) => accum || bool, false)) {
      setEdit(true);
      setProcessing(false);
      return;
    }

    setTimeout(async () => {
      const toSave = {
        ...props.data,
        ex_id: EX.id,
        rep_id: props.data.id,
        locale: CODE,
      };

      // POST or PATCH?
      let res = null;
      if (props.data.id === null) {
        res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/create/representative`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(toSave),
          },
        )
          .then((val) => val.json())
          .catch((e) => {
            console.error(e.message);
          });
      } else {
        res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/patch/representative`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(toSave),
          },
        )
          .then((val) => val.json())
          .catch((e) => {
            console.error(e.message);
          });
      }

      // Passing states
      if (res.success) {
        props.handleSave(props.idx, { ...props.data, id: res.id });
        if (skipCheck) setReminderDialog(true);
      } else {
        setEdit(true);
        if (res.message.includes('Booked: ')) {
          const stringified = res.message.split('Booked: ')[1];
          passConflictApp(JSON.parse(stringified));
        }
      }

      setProcessing(false);
    }, 300);
  };

  const handleDelete = () => {
    if (
      LAST_ID !== null &&
      ASSET.dateFromSlotId(LAST_ID).valueOf() > moment().valueOf()
    ) {
      setSnack(true);
      return;
    }

    setLast(props.length === 1);
    setOpen(true);
  };

  return (
    <Card id={`rep-${props.idx}`} className={classes.root} elevation={4}>
      <Snack
        open={snack}
        onClose={() => setSnack(false)}
        text={t('deleteMessage')}
        buttonText={t('close')}
      />
      <ConflictDialog
        open={saveConflict}
        handleClose={closeConflict}
        conflicts={conflictApp}
        asset={ASSET}
      />
      <CardHeader
        title={
          props.data.name === ''
            ? `${t('rep')} ${props.idx + 1}`
            : props.data.name
        }
        action={
          <div>
            {!shouldSuspendMultipleRepresentative && (
              <Button
                disabled={isProcessing}
                color="primary"
                onClick={handleDelete}
                style={{ marginRight: '0.5rem' }}
              >
                {t('delete')}
              </Button>
            )}
            <Button
              variant="contained"
              disabled={isProcessing}
              color="primary"
              onClick={props.edit ? handleSave : () => setEdit(true)}
            >
              {isProcessing ? t('saving') : props.edit ? t('save') : t('edit')}
              {isProcessing && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ marginLeft: '1rem' }}
                />
              )}
            </Button>
          </div>
        }
      />
      <CardContent className={classes.content}>
        <Form
          idx={props.idx}
          edit={props.edit}
          details={props.data}
          handleInput={props.handleInput}
          valid={validation}
          deletedIdx={props.deletedIdx}
          passOffset={props.passOffset}
        />
      </CardContent>
      <CardContent style={{ paddingTop: '0.5rem' }}>
        <Calendar
          idx={props.idx}
          edit={props.edit}
          veto={props.data.veto}
          appointments={APPOINTMENT_IDS}
          changeVeto={props.changeVeto}
          asset={ASSET}
        />
      </CardContent>
      <Dialog open={open} onClose={() => setOpen(false)}>
        {last && <DialogTitle>{t('deleteRepTitle')}</DialogTitle>}
        <DialogContent>
          <DialogContentText>
            {last
              ? t('deleteLastRepMessage')
              : t('deleteRepMessage', { person: props.data.name })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="contained"
          >
            {t('no')}
          </Button>
          <Button
            onClick={() => {
              props.handleDelete(props.idx);
              setOpen(false);
            }}
            color="primary"
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <EditDetailsDialog
        open={changeDialog === true}
        handleClose={() => {
          setChangeDialog(false);
          setEdit(true);
          setProcessing(false);
        }}
        applySave={() => {
          setChangeDialog(false);
          skipCheck = true;
          handleSave();
        }}
      />
      <ReminderDialog
        open={reminderDialog}
        handleClose={() => setReminderDialog(false)}
      />
    </Card>
  );
}

export default Representative;
