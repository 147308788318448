// React Stuffs
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TranslateIcon from '@material-ui/icons/Translate';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router';

// Material UI Icons
import CancelIcon from '@material-ui/icons/Cancel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// Source Files
import isEmpty from 'validator/es/lib/isEmpty';
import queryString from 'query-string';
import {
  toLocaleDateString,
  toLocaleDayString,
  toLocaleTimeString,
  getTimeZoneString,
  langIndex,
  helpWidget,
  filterLang,
} from '../../Functions';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  body: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button: {
    height: '40px',
  },
  details: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  detailHeading: {
    textAlign: 'center',
    paddingBottom: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 500,
  },
  barLeft: {
    width: '250px',
    textAlign: 'left',
  },
  barRight: {
    width: '250px',
    textAlign: 'right',
  },
}));

// eslint-disable-next-line complexity
function Cancel() {
  const { t } = useTranslation();
  // Constants and States
  const { id: APP_ID } = useParams();
  const desktop = useMediaQuery('(min-width:965px)');
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const [app, setApp] = useState(null);
  const [rep, setRep] = useState(null);
  const [ex, setEx] = useState(null);
  const [EVENT, setEvent] = useState(null);
  const [cancelled, setCancelled] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const filteredLang = filterLang(EVENT?.id);
  const CODE = filteredLang[selectedIndex].code;
  // eslint-disable-next-line no-undef
  if (typeof ze === 'function') zE('webWidget', 'setLocale', CODE);
  const [reason, setReason] = useState('');
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState(false);
  const [initiator, setInitiator] = useState(null);

  const { hasApprovalMechanism } = EVENT?.feature_flag ?? {};
  const encodedAttendee =
    queryString.parse(location.search)?.attendee ||
    window.localStorage.getItem('attendee');
  const [authAttendee, setAttendee] = useState(null);

  const handleRedirect = useCallback(() => {
    if (authAttendee.ex_id && authAttendee.auth_token) {
      const { ex_id, auth_token } = authAttendee;
      window.location = `/exhibitor/${ex_id}?auth_token=${auth_token}`;
    }
  }, [authAttendee]);

  // Effects
  useEffect(() => {
    const searchObj = queryString.parse(location.search);
    const init = searchObj.initiator;
    if (init !== undefined) setInitiator(init);
    history.replace(`/cancel/${APP_ID}`);
  }, [APP_ID, history, location.search]);

  const setLanguage = useCallback(
    (idx) => {
      setSelectedIndex(idx);
      const lng = filteredLang[idx].code;
      window.localStorage.setItem('locale', lng);
      i18n.changeLanguage(lng);
    },
    [filteredLang],
  );

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setLanguage(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      try {
        const appRes = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/get/appointment/${APP_ID}`,
        )
          .then((val) => val.json())
          .catch((e) => console.log(e.message));
        if (appRes.success) {
          setApp(appRes.data);
          if (appRes.data?.status === 'CANCELLED') setCancelled(true);
        } else throw new Error(appRes.message);
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [APP_ID]);

  useEffect(() => {
    (async () => {
      try {
        if (app !== null) {
          const repRes = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/get_all/representative/${app.ex_id}`,
          )
            .then((val) => val.json())
            .catch((e) => console.log(e.message));
          if (repRes.success) {
            setRep(repRes.data.filter((rep) => rep.id === app.rep_id)[0]);
            const exRes = await fetch(
              `${process.env.REACT_APP_API_ENDPOINT}/get/exhibitor/${app.ex_id}`,
            )
              .then((val) => {
                return val.json();
              })
              .catch((e) => console.log(e.message));
            if (exRes.success) setEx(exRes.data);
            else throw new Error(exRes.message);
          } else throw new Error(repRes.message);
        }
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [app]);

  useEffect(() => {
    (async () => {
      try {
        if (ex !== null) {
          const evRes = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/get/event/${ex.event_id}`,
          )
            .then((val) => val.json())
            .catch((e) => console.log(e.message));
          if (evRes.success) {
            setEvent(evRes.data);
            const { id } = evRes.data;
            setSelectedIndex(langIndex(filterLang(id)));
          } else throw new Error(evRes.message);
        }
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [ex]);

  useEffect(() => {
    if (rep !== null && app !== null && ex !== null && EVENT !== null) {
      if (initiator === rep.id) {
        setInitiator('REPRESENTATIVE');
        // SetLanguage(filteredLang.map((lang) => lang.code).indexOf(rep.locale));
      } else if (initiator === app.attendee.email) {
        setInitiator('ATTENDEE');
        setLanguage(
          filteredLang.map((lang) => lang.code).indexOf(app.attendee.locale),
        );
      }
      // Else setError(true);
    }
  }, [app, rep, ex, EVENT, initiator, setLanguage, filteredLang]);

  useEffect(() => {
    (async () => {
      if (EVENT?.eventDomain && encodedAttendee) {
        try {
          window.localStorage.setItem('attendee', encodedAttendee);
          const attendeeData = JSON.parse(
            decodeURIComponent(escape(atob(encodedAttendee))),
          );
          setAttendee(attendeeData);
        } catch (e) {
          console.log(e.message);
        }
      }
    })();
  }, [EVENT, encodedAttendee]);

  function checkReason() {
    if (EVENT.require_reason && isEmpty(reason, { ignore_whitespace: true })) {
      setValidate(true);
      return true;
    }

    setValidate(false);
    return false;
  }

  function handleInput(e) {
    const { value } = e.target;
    setReason(value);
  }

  const handleCancel = async () => {
    setProcessing(true);
    if (checkReason()) {
      setProcessing(false);
      return;
    }

    const data = {
      ex_id: ex.id,
      rep_id: rep.id,
      app_id: app.id,
      slot_id: app.slot_id,
      reason,
    };
    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/delete/appointment`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    )
      .then((val) => val.json())
      .catch((e) => {
        console.log(e.message);
      });
    setProcessing(false);
    if (res.success) setCancelled(true);
    else alert(`Failed to cancel appointment: ${res.message}`);
  };

  if (error) return <Redirect to={`/error`} />;

  const isCancelDisabled = app && app.isCancelDisabled;

  return (
    <Container component="main" maxWidth="sm">
      {EVENT !== null && ex !== null && rep !== null && app !== null ? (
        <div>
          <div className={classes.root}>
            {!desktop && (
              <div
                style={{
                  position: 'absolute',
                  right: '0.5rem',
                  bottom: '0.5rem',
                }}
              >
                <IconButton color="inherit" onClick={helpWidget}>
                  <HelpOutlineIcon
                    style={{ fill: theme.palette.primary.main }}
                  />
                </IconButton>
              </div>
            )}
            <AppBar position="fixed">
              <Toolbar>
                <div className={desktop ? classes.barLeft : null}>
                  <IconButton color="inherit" style={{ visibility: 'hidden' }}>
                    <TranslateIcon />
                  </IconButton>
                </div>
                <Typography variant="h5" className={classes.title}>
                  {EVENT.name}
                </Typography>
                <div className={desktop ? classes.barRight : null}>
                  {desktop && (
                    <IconButton color="inherit" onClick={helpWidget}>
                      <HelpOutlineIcon style={{ fill: 'white' }} />
                    </IconButton>
                  )}
                  <IconButton
                    color="inherit"
                    onClick={handleClickListItem}
                    disabled={desktop}
                  >
                    <TranslateIcon style={{ fill: 'white' }} />
                  </IconButton>
                  {desktop && (
                    <Select
                      value={filteredLang[selectedIndex].name}
                      disableUnderline
                      style={{ color: 'white' }}
                    >
                      {filteredLang.map((option, idx) => (
                        <MenuItem
                          key={option.name}
                          value={option.name}
                          onClick={() => setLanguage(idx)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </Toolbar>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {filteredLang.map((option, index) => (
                  <MenuItem
                    key={option.name}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
            </AppBar>
          </div>
          {cancelled ? (
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <CancelIcon />
              </Avatar>
              <Typography variant="h6">{t('cancelMessage')}</Typography>
              <div style={{ width: '100%' }}>
                <Paper className={classes.details} elevation={2}>
                  <Typography variant="h6" className={classes.detailHeading}>
                    {t('appDetails', CODE)}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ textDecoration: 'line-through' }}
                  >
                    {`${t('date')}${toLocaleDateString(
                      app.start,
                      CODE,
                    )} (${toLocaleDayString(app.start, CODE)})`}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ textDecoration: 'line-through' }}
                  >
                    {`${t('time')}${toLocaleTimeString(
                      app.start,
                      CODE,
                    )} - ${toLocaleTimeString(app.end, CODE)}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ textDecoration: 'line-through' }}
                  >
                    {`${t('tz')}${getTimeZoneString(CODE)}`}
                  </Typography>
                </Paper>

                {hasApprovalMechanism && !authAttendee && (
                  <Button
                    fullWidth
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const win = window.open(`/appointment/${ex.id}`);
                      win.focus();
                    }}
                  >
                    {t('scheduleAnotherApp')}
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <CancelIcon />
              </Avatar>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                {initiator === 'REPRESENTATIVE'
                  ? t('appointmentWith', {
                      person:
                        app.attendee.name ??
                        `${app.attendee.first} ${app.attendee.last}`,
                    })
                  : t('from', { representative: rep.name, exhibitor: ex.name })}
              </Typography>
              <div style={{ width: '100%' }}>
                <Typography variant="body1">
                  {`${t('date')}${toLocaleDateString(
                    app.start,
                    CODE,
                  )} (${toLocaleDayString(app.start, CODE)})`}
                </Typography>
                <Typography variant="body1">
                  {`${t('time')}${toLocaleTimeString(
                    app.start,
                    CODE,
                  )} - ${toLocaleTimeString(app.end, CODE)}`}
                </Typography>
                <Typography variant="body1">
                  {`${t('tz')}${getTimeZoneString(CODE)}`}
                </Typography>
              </div>
              <div style={{ width: '100%' }}>
                {isCancelDisabled ? (
                  <p>{t('cancelDisableRemark')}</p>
                ) : (
                  <>
                    <Paper className={classes.details} elevation={2}>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        {`${t('cancelApp')}?`}
                      </Typography>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="reason"
                        name="reason"
                        required={EVENT.require_reason}
                        error={validate}
                        helperText={validate ? t('required') : undefined}
                        label={t('reason')}
                        value={reason}
                        onInput={handleInput}
                        multiline
                        rows={4}
                      />
                    </Paper>
                    <Button
                      fullWidth
                      className={classes.button}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleCancel}
                      disabled={isProcessing}
                    >
                      {t('cancelApp')}
                      {isProcessing && (
                        <CircularProgress
                          size={25}
                          color="inherit"
                          style={{ marginLeft: '1rem' }}
                        />
                      )}
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}

          {hasApprovalMechanism && authAttendee && (
            <Button
              fullWidth
              color="primary"
              onClick={handleRedirect}
              style={{ marginTop: '10px' }}
            >
              {t('goToAppointments')}
            </Button>
          )}
        </div>
      ) : (
        <div className="abs-center">
          <CircularProgress />
        </div>
      )}
    </Container>
  );
}

export default Cancel;
