import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';

import { ListHeader, ListRow, ListItemCell } from './ListBaseRow';

const DataRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;

  &:hover {
    background-color: rgb(250, 250, 250);
  }
`;

const EmptyStateWrapper = styled(ListItemCell)`
  height: 100%;
`;

const StyledListItemCell = styled(ListItemCell)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ListItemCellLabel = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

const Divider = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 5px;
`;

const GeneralList = ({
  columns,
  rows,
  emptyState,
  sortingConfig,
  handleSorting,
}) => {
  const { t } = useTranslation();
  const timestamp = Date.now();
  const validRows = rows.filter((it) => it.rawEnd > timestamp);
  const expiredRows = rows.filter((it) => it.rawEnd <= timestamp);

  if (rows.length === 0)
    return <EmptyStateWrapper>{emptyState}</EmptyStateWrapper>;

  return (
    <>
      <ListHeader>
        <ListRow>
          {columns.map(({ id, label }) => {
            if (id === 'dateTime' || id === 'createTime') {
              return (
                <StyledListItemCell key={id}>
                  <ListItemCellLabel color="textSecondary">
                    {label}
                  </ListItemCellLabel>
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleSorting(
                        id === 'dateTime' ? 'rawStart' : 'rawCreateTime',
                      )
                    }
                  >
                    {sortingConfig &&
                      id === 'dateTime' &&
                      (sortingConfig.rawStart === 'ASC' ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      ))}

                    {sortingConfig &&
                      id === 'createTime' &&
                      (sortingConfig.rawCreateTime === 'ASC' ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      ))}
                  </IconButton>
                </StyledListItemCell>
              );
            }

            return (
              <ListItemCell key={id}>
                <ListItemCellLabel color="textSecondary">
                  {label}
                </ListItemCellLabel>
              </ListItemCell>
            );
          })}
        </ListRow>
      </ListHeader>

      {validRows.map((row) => (
        <DataRow key={row.id}>{row.content}</DataRow>
      ))}

      {expiredRows.length > 0 && (
        <>
          <ListItemCellLabel
            color="textSecondary"
            style={{ marginTop: '10px' }}
          >
            {t('expired')}
          </ListItemCellLabel>

          <Divider />

          {expiredRows.map((row) => (
            <DataRow key={row.id}>{row.content}</DataRow>
          ))}
        </>
      )}
    </>
  );
};

export default GeneralList;
