// React Stuffs
import React, { useContext, useEffect, useState } from 'react';
import { LangCodeContext, AssetContext } from '../Context';
import { Redirect, useLocation } from 'react-router-dom';
// Material UI
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
// Material UI Icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// Source Files
import moment from 'moment';
import queryString from 'query-string';
import {
  toLocaleDayStringShort,
  toLocaleYearMonthString,
  timeZero,
} from '../../Functions';

const useStyles = makeStyles(() => {
  return {
    monthSelector: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      maxWidth: '300px',
      margin: 'auto',
    },
    monthSelectorExpand: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      maxWidth: '464px',
      margin: 'auto',
    },
    heading: {
      display: 'flex',
      justifyContent: 'center',
      maxHeight: '16px',
      alignItems: 'center',
      marginBottom: '0.75rem',
    },
    headingExpand: {
      display: 'flex',
      justifyContent: 'flex-end',
      maxHeight: '16px',
      alignItems: 'center',
      maxWidth: '464px',
      margin: 'auto',
      marginBottom: '1.5rem',
    },
    subHeading: {
      color: 'rgba(0, 0, 0, 0.38)',
      width: '36px',
      margin: '2px',
      textAlign: 'center',
    },
    subHeadingExpand: {
      color: 'rgba(0, 0, 0, 0.38)',
      width: '62px',
      margin: '2px',
      textAlign: 'center',
    },
    item: {
      display: 'block',
    },
    icon: {
      width: '36px',
      height: '36px',
      margin: '2px',
      padding: 0,
      fontSize: '0.75rem',
      fontWeight: '500',
    },
    iconExpand: {
      width: '50px',
      height: '50px',
      margin: '8px',
      padding: 0,
      fontSize: '1rem',
      fontWeight: '500',
    },
    week: {
      display: 'flex',
      justifyContent: 'center',
    },
    weekExpand: {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

function CalendarHeadings({ desktop }) {
  const CODE = useContext(LangCodeContext).code;
  const classes = useStyles();
  const generateHeadings = () => {
    const now = moment();
    now.subtract(now.day(), 'd');
    return Array.from({ length: 7 }, (x, i) =>
      toLocaleDayStringShort(now.clone().add(i, 'd'), CODE),
    );
  };

  const HEADINGS = generateHeadings(CODE);

  return (
    <div className={desktop ? classes.headingExpand : classes.heading}>
      {HEADINGS.map((heading, idx) => {
        return (
          <Typography
            variant="caption"
            className={desktop ? classes.subHeadingExpand : classes.subHeading}
            key={`calHeading-${idx}`}
          >
            {heading}
          </Typography>
        );
      })}
    </div>
  );
}

function Date({ data, desktop, selectedDate, passToCalendar }) {
  const classes = useStyles();
  const theme = useTheme();
  const { date, render, available, withinRange } = data;
  const DATE = moment(date);
  const selected = selectedDate === DATE.format('YYYY-MM-DD');

  const backgroundColor = () => {
    if (!withinRange) return 'rgba(221, 221, 221, 0.1)';
    if (selected) return theme.palette.primary.light;
    if (available) return 'rgba(75, 199, 100, 0.2)';
    return 'rgba(221, 221, 221, 0.1)';
  };

  const textColor = () => {
    if (available) return 'rgb(0, 128, 0)';
    return 'rgb(128, 128, 128)';
  };

  if (selected && !available) return <Redirect to={`/error`} />;

  return (
    <Paper className={classes.item} elevation={0}>
      <IconButton
        className={desktop ? classes.iconExpand : classes.icon}
        style={{
          visibility: render ? undefined : 'hidden',
          backgroundColor: backgroundColor(),
          color: selected ? theme.palette.primary.main : textColor(),
        }}
        disabled={!withinRange || !available}
        onClick={() => passToCalendar(DATE.format('YYYY-MM-DD'))}
      >
        <Typography variant="body2">{DATE.date()}</Typography>
      </IconButton>
    </Paper>
  );
}

function Calendar({ desktop, calendar, passToSelectDateSlot }) {
  // Constants, Context and States
  const classes = useStyles();
  const CODE = useContext(LangCodeContext).code;
  const ASSET = useContext(AssetContext);
  const [monthIdx, setMonthIdx] = useState(0);
  const [error, setError] = useState(false);
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(
    queryString.parse(location.search).date || null,
  );
  // Effects
  useEffect(() => {
    // This effect handles when there is a date in the URL
    // Start with the month with the first available date
    if (selectedDate === null) {
      const initialIndex = () => {
        let i = 0;
        for (; i < calendar.length; ++i)
          for (const weekArray of calendar[i].array)
            for (const dayObj of weekArray)
              if (dayObj.render && dayObj.available) return i;
        return -1;
      };

      const START = moment(ASSET.START);
      const initial = initialIndex();
      const diffMonths =
        moment().month() +
        moment().year() * 12 -
        (START.month() + START.year() * 12);
      setMonthIdx(initial === -1 ? 0 : Math.max(initial, diffMonths));
    } else {
      // Validate the date
      const DATE = moment(selectedDate, 'YYYY-MM-DD');
      const END = moment(ASSET.END);
      if (DATE.isBetween(timeZero(), END, null, '[]')) {
        const startMonthIdx = calendar.findIndex(
          (monthData) => monthData.name === toLocaleYearMonthString(DATE, CODE),
        );
        setMonthIdx(startMonthIdx === -1 ? 0 : startMonthIdx);
      } else setError(true);
    }
  }, [ASSET.END, ASSET.START, CODE, calendar, selectedDate]);

  // Functions
  function handleSelect(date) {
    const toSend = date === selectedDate ? null : date;
    setSelectedDate(toSend);
    passToSelectDateSlot(toSend);
  }

  function clearDate() {
    if (selectedDate) handleSelect(null);
  }

  if (error) return <Redirect to={`/error`} />;
  return (
    <div>
      <div
        className={
          desktop ? classes.monthSelectorExpand : classes.monthSelector
        }
      >
        <IconButton
          onClick={() => {
            clearDate();
            setMonthIdx((prevState) => prevState - 1);
          }}
          disabled={monthIdx === 0}
          style={{ padding: '12px' }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography variant="body1" style={{ lineHeight: 3 }}>
          {calendar[monthIdx].name}
        </Typography>
        <IconButton
          onClick={() => {
            clearDate();
            setMonthIdx((prevState) => prevState + 1);
          }}
          disabled={monthIdx === calendar.length - 1}
          style={{ padding: '12px' }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <CalendarHeadings desktop={desktop} />
      {calendar[monthIdx].array.map((week, idx) => {
        return (
          <div
            key={`M${monthIdx}W${idx}`}
            className={
              desktop && selectedDate !== null
                ? classes.weekExpand
                : classes.week
            }
          >
            {week.map((day) => {
              return (
                <Date
                  key={`CalendarButton-${day.date}`}
                  desktop={desktop}
                  data={day}
                  passToCalendar={(date) => handleSelect(date)}
                  selectedDate={selectedDate}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Calendar;
