import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';
import frTranslation from './locales/fr.json';
import jaTranslation from './locales/ja.json';
import koTranslation from './locales/ko.json';
import viTranslation from './locales/vi.json';
import zhCnTranslation from './locales/zh-CN.json';
import zhTwTranslation from './locales/zh-TW.json';

const LOCAL_STORAGE_KEY = 'locale';

export const LANG = [
  { code: 'en', name: 'English' },
  { code: 'zh-TW', name: '‪繁體中文‬' },
  { code: 'zh-CN', name: '简体中文' },
  { code: 'ko', name: '한국어' },
  { code: 'fr', name: 'Français' },
  { code: 'es', name: 'Español' },
  { code: 'ja', name: '日本語' },
  { code: 'vi', name: 'Tiếng Việt' },
];

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  ja: {
    translation: jaTranslation,
  },
  ko: {
    translation: koTranslation,
  },
  vi: {
    translation: viTranslation,
  },
  'zh-CN': {
    translation: zhCnTranslation,
  },
  'zh-TW': {
    translation: zhTwTranslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: LOCAL_STORAGE_KEY,
      caches: ['localStorage'],
    },
    fallbackLng: 'en',
    whitelist: [...LANG.map((l) => l.code)],
  });

export default i18n;
