// React Stuffs
import React, { useContext, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Redirect, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  ExhibitorContext,
  EventContext,
  RepContext,
  LangCodeContext,
  AttendeeContext,
} from '../Context';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Material UI Icons
import DoneIcon from '@material-ui/icons/Done';

// Source Files
import { google, ics } from 'calendar-link';
import { saveAs } from 'file-saver';
import TopBar from './TopBar';
import {
  toLocaleDateString,
  toLocaleDayString,
  toLocaleTimeString,
  getTimeZoneString,
} from '../../Functions';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { api } from '../../services/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  body: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: '4rem',
  },
  details: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    '& p': {
      fontSize: 14,
      lineHeight: '143%',
    },
    '& .MuiTypography-subtitle1': {
      fontWeight: 700,
      fontSize: 16,
    },
  },
  detailHeading: {
    textAlign: 'center',
    paddingBottom: theme.spacing(1),
  },
  buttonFlex: {
    width: '100%',
    display: 'flex',
    gap: 16,
  },
  buttonCancel: {
    background: 'rgba(216, 12, 36, 0.08)',
    marginBottom: 16,
  },
}));

function Confirmation({ location }) {
  const { t } = useTranslation();

  // Constants, Context and States
  const classes = useStyles();
  const EVENT = useContext(EventContext);
  const EX = useContext(ExhibitorContext);
  const REPS = useContext(RepContext);
  const CODE = useContext(LangCodeContext).code;
  const authAttendee = useContext(AttendeeContext);

  const { hasApprovalMechanism } = useFeatureFlag();

  const { search } = useLocation();
  if (search === undefined) {
    return <Redirect to={`/appointment/${EX.id}/all`} />;
  }

  const handleRedirect = useCallback(() => {
    if (authAttendee.ex_id && authAttendee.auth_token) {
      const { ex_id, auth_token } = authAttendee;
      window.location = `/exhibitor/${ex_id}?auth_token=${auth_token}`;
    }
  }, [authAttendee]);

  // Effects
  // Functions

  const { repIdx, start, appId, attendeeEmail } = location.data;

  const { data: app } = useQuery(['get-appointment'], async () =>
    api.appointment.getAppointment({ app_id: appId }),
  );

  const ATTENDEE = (app && app.attendee) ?? {};

  const START = moment(start);
  const END = START.clone().add(EVENT.slot_duration, 'minutes');

  const event = {
    title: t('icsTitle', {
      representative: REPS[repIdx].name,
      exhibitor: EX.name,
    }),
    description: REPS[repIdx].link,
    start: START.valueOf(),
    end: END.valueOf(),
  };

  const fullName = ATTENDEE.name ?? `${ATTENDEE.first} ${ATTENDEE.last}`;

  const shouldShowGoToAppointmentButton = useMemo(
    () =>
      hasApprovalMechanism &&
      authAttendee &&
      authAttendee.ex_id &&
      authAttendee.auth_token,
    [hasApprovalMechanism, authAttendee],
  );

  return (
    <Container component="main" maxWidth="sm">
      <TopBar hide={true} goBack={() => {}} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <DoneIcon />
        </Avatar>
        <Typography variant="h6">
          {hasApprovalMechanism ? t('invitationSent') : t('confirmed')}
        </Typography>
        <Typography variant="subtitle1">
          {hasApprovalMechanism
            ? t('pendingResponse', { name: REPS[repIdx].name })
            : t('meetWhoFromWhere', {
                person: REPS[repIdx].name,
                exhibitor: EX.name,
              })}
        </Typography>
        <div style={{ width: '100%' }}>
          <Paper className={classes.details} elevation={2}>
            <Typography variant="subtitle1" align="center">
              {t('appointmentTitle')}
            </Typography>
            <p>
              {`${t('date')}${toLocaleDateString(
                START,
                CODE,
              )} (${toLocaleDayString(START, CODE)})`}
              <br />
              {`${t('time')}${toLocaleTimeString(
                START,
                CODE,
              )} - ${toLocaleTimeString(END, CODE)} (${getTimeZoneString(
                CODE,
              )})`}
              <br />
              {ATTENDEE.company
                ? t('detailsInviterNameCompany', {
                    fullName,
                    company: ATTENDEE.company,
                  })
                : t('detailsInviterName', {
                    fullName,
                  })}
              <br />
              {REPS[repIdx].company
                ? t('detailsInviteeNameCompany', {
                    fullName: REPS[repIdx].name,
                    company: REPS[repIdx].company,
                  })
                : t('detailsInviteeName', {
                    fullName: REPS[repIdx].name,
                  })}
            </p>
            {!hasApprovalMechanism && (
              <div className={classes.buttonFlex}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const googleCalendar = google(event);
                    const win = window.open(googleCalendar);
                    win.focus();
                  }}
                >
                  {t('addToGoogle')}
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    saveAs(ics(event), `${event.title}.ics`);
                  }}
                >
                  {t('addToOthers')}
                </Button>
              </div>
            )}
          </Paper>
        </div>
        <Button
          fullWidth
          type="submit"
          variant="text"
          color="primary"
          className={classes.buttonCancel}
          onClick={() => {
            const win = window.open(
              `/cancel/${appId}?initiator=${ATTENDEE.email ?? attendeeEmail}`,
            );
            win.focus();
          }}
        >
          {t('cancelApp')}
        </Button>
        {shouldShowGoToAppointmentButton && (
          <Button fullWidth color="primary" onClick={handleRedirect}>
            {t('goToAppointments')}
          </Button>
        )}
      </div>
    </Container>
  );
}

export default Confirmation;
