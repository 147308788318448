// React Stuffs
import React, { useContext, useState } from 'react';
import {
  ExhibitorContext,
  RepContext,
  LangCodeContext,
  AssetContext,
} from '../Context';
import { useParams } from 'react-router';
import { Redirect, useHistory } from 'react-router-dom';
// Material UI
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Matierial UI Icons
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// Source Files
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import TopBar from './TopBar';
import Calendar from './Calendar';
import Slots from './Slots';
import {
  getTimeZoneString,
  toLocaleYearMonthString,
  toLocaleDateString,
  toLocaleDayString,
  timeZero,
} from '../../Functions';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(10),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
    },
  };
});

function checkAvailability(date, unavailable, ASSET) {
  const slotIds = ASSET.getDateSlotIds(date);
  const unavailIds = _.intersection(slotIds, unavailable);
  const difference = _.difference(slotIds, unavailIds);
  if (difference.length > 0) {
    const lastId = Math.max(...difference);
    if (
      lastId !== undefined &&
      moment().valueOf() > ASSET.dateFromSlotId(lastId).valueOf()
    )
      return false;
  }

  if (unavailIds.length === slotIds.length) return false;
  return true;
}

function generateCalendar(ASSET, unavailable, locale) {
  const START = moment(ASSET.START);
  const END = moment(ASSET.END);
  const NOW = timeZero();
  // Month(s) before the current month will not be shown
  // const calStart = NOW.valueOf() > timeZero(START.clone()).valueOf() ? NOW.clone() : timeZero(START.clone());
  const calStart = timeZero(START.clone());
  const calEnd =
    END.hour() === 0 && END.minute() === 0
      ? timeZero(END.clone())
      : END.clone().hour(23).minute(23);
  const startOfMonth = calStart.clone().date(1);
  const calArray = [];
  const numMonths = () =>
    calEnd.year() * 12 +
    calEnd.month() -
    (calStart.year() * 12 + calStart.month()) +
    1;
  for (let i = 0; i < numMonths(); ++i) {
    // Start from Sunday
    const startOffset = startOfMonth.clone().subtract(startOfMonth.day(), 'd');
    const monthArray = [];
    for (let j = 0; j < 6; ++j) {
      // Maximum 6 rows in month view
      const weekArray = [];
      for (let k = 0; k < 7; ++k) {
        // 7 days in a week
        const currentDate = startOffset.clone().add(j * 7 + k, 'd');
        const withinRange = currentDate.isBetween(
          NOW > START ? NOW : timeZero(START),
          calEnd,
          null,
          '[]',
        );
        weekArray.push({
          date: currentDate.format('YYYY-MM-DD'),
          render: currentDate.month() === (i + calStart.month()) % 12,
          available: withinRange
            ? checkAvailability(currentDate, unavailable, ASSET)
            : false,
          withinRange,
        });
      }

      monthArray.push(weekArray);
    }

    calArray.push({
      name: toLocaleYearMonthString(startOfMonth, locale),
      array: monthArray,
    });
    startOfMonth.add(1, 'month');
  }

  return calArray;
}

function SelectDateSlot(props) {
  const { t } = useTranslation();
  const { repIdx: REP_IDX } = useParams();
  // Constants, Context and States
  const classes = useStyles();
  const REP = useContext(RepContext)[REP_IDX];
  const EX = useContext(ExhibitorContext);
  const CODE = useContext(LangCodeContext).code;
  const ASSET = useContext(AssetContext);
  const desktop = useMediaQuery('(min-width:965px)');
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(null);
  const [back, setBack] = useState(false);
  const previous = `/appointment/${EX.id}/all`;
  // Effects
  // Functions
  function handleDateSelect(date) {
    const searchObj = {};
    if (date !== null) searchObj.date = date;
    const searchString = '?' + queryString.stringify(searchObj);
    history.replace(`/appointment/${EX.id}/R${REP_IDX}/select${searchString}`);
    setSelectedDate(date);
  }

  function helperText() {
    if (desktop) return t('pickDateSlot');
    if (selectedDate === null) return t('pickDate');
    return t('pickSlot');
  }

  function goBack() {
    if (selectedDate === null) setBack(true);
    else handleDateSelect(null);
  }

  if (REP === undefined) return <Redirect to={`/appointment/${EX.id}/all`} />;

  if (back) return <Redirect to={previous} />;

  const UNAVAILABLE = _.concat(
    REP.veto,
    REP.appointments.map((app) => app.slot_id),
  );

  return (
    <Container component="main" maxWidth={desktop ? 'md' : 'sm'}>
      <TopBar hide={false} goBack={goBack} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          {!desktop && selectedDate !== null ? (
            <AccessTimeIcon />
          ) : (
            <EventIcon />
          )}
        </Avatar>
        <Typography variant="h6">
          {!desktop && selectedDate !== null
            ? `${toLocaleDateString(
                moment(selectedDate, 'YYYY-MM-DD'),
                CODE,
              )} (${toLocaleDayString(
                moment(selectedDate, 'YYYY-MM-DD'),
                CODE,
              )})`
            : EX.name}
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 400 }}>
          {!desktop && selectedDate !== null
            ? `${getTimeZoneString(CODE)}`
            : REP.name}
        </Typography>
        <Typography variant="subtitle1">{helperText()}</Typography>
        <Typography variant="subtitle1">
          {!desktop && selectedDate !== null
            ? null
            : `${t('tz')} ${getTimeZoneString(CODE)}`}
        </Typography>
        <div style={{ display: 'flex', marginTop: '1rem', width: '100%' }}>
          <Container
            style={{
              width: desktop && selectedDate !== null ? '50%' : '100%',
              padding: 0,
            }}
          >
            {!desktop && selectedDate !== null ? (
              <Slots // Mobile Slot View
                date={selectedDate}
                repIdx={REP_IDX}
                passToSelectDateSlot={(date) => handleDateSelect(date)}
                {...props}
              />
            ) : (
              <Calendar // Calendar Only View
                desktop={desktop}
                calendar={generateCalendar(ASSET, UNAVAILABLE, CODE)}
                passToSelectDateSlot={(date) => handleDateSelect(date)}
              />
            )}
          </Container>
          {desktop &&
            selectedDate !== null && ( // Right side of desktop
              <Container
                style={{
                  width: '50%',
                  padding: '0.5rem 0 0 0',
                  height: '484px',
                  overflow: 'auto',
                }}
              >
                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                  {toLocaleDateString(moment(selectedDate, 'YYYY-MM-DD'), CODE)}
                </Typography>
                <Slots
                  date={selectedDate}
                  repIdx={REP_IDX}
                  passToSelectDateSlot={(date) => handleDateSelect(date)}
                  {...props}
                />
              </Container>
            )}
        </div>
      </div>
    </Container>
  );
}

export default SelectDateSlot;
