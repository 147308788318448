import i18n from 'i18next';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';

import {
  ExhibitorContext,
  EventContext,
  RepContext,
  LangCodeContext,
} from '../Context';
import CircularProgress from '@material-ui/core/CircularProgress';
import Representative from './Representative';
import {
  getTimeZoneString,
  toLocaleDateString,
  toLocaleTimeString,
  langIndex,
  helpWidget,
  filterLang,
} from '../../Functions';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TranslateIcon from '@material-ui/icons/Translate';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(9),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 500,
  },
  toolbar: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
}));

function Edit() {
  const { t } = useTranslation();

  const history = useHistory();
  const EVENT = useContext(EventContext);
  const EX = useContext(ExhibitorContext);
  const REPS = useContext(RepContext);
  const classes = useStyles();
  const filteredLang = filterLang(EVENT?.id);
  const desktop = useMediaQuery('(min-width:965px)');
  // For Translation
  const [selectedIndex, setSelectedIndex] = useState(langIndex(filteredLang));
  const CODE = filteredLang[selectedIndex].code;
  // eslint-disable-next-line no-undef
  if (typeof ze === 'function') zE('webWidget', 'setLocale', CODE);
  const [savedArr, setSavedArr] = useState([]);
  const [repArr, setRepArr] = useState([]);
  const [editArr, setEditArr] = useState([]);
  const [deletedIdx, setDeletedIdx] = useState([]);
  const [booleanSaved, setBoolean] = useState([]);
  const emptyRep = useMemo(() => {
    return {
      name: '',
      email: '',
      link: '',
      desc: '',
      veto: [],
      id: null,
      appointments: [],
      offset: moment().utcOffset(),
      local: true,
    };
  }, []);

  const booleanTemplate = useMemo(() => {
    return {
      name: true,
      email: true,
      link: true,
      desc: true,
      veto: true,
    };
  }, []);

  const { hasApprovalMechanism, shouldSuspendMultipleRepresentative } =
    useFeatureFlag();

  useEffect(() => {
    if (!hasApprovalMechanism) {
      history.replace(`/exhibitor/${EX.id}`);
    }
  }, [history, EX.id, hasApprovalMechanism]);

  const handleAdd = useCallback(() => {
    setRepArr((prevState) => [...prevState, emptyRep]); // Create "Template"
    setSavedArr((prevState) => [...prevState, emptyRep]);
    setEditArr((prevState) => [...prevState, true]);
    setBoolean((prevState) => [...prevState, booleanTemplate]);
  }, [booleanTemplate, emptyRep]);

  useEffect(() => {
    if (REPS !== null) {
      if (REPS.length === 0) {
        // If no representative, add the default empty representative
        handleAdd();
      } else {
        const patchREPS = REPS.map((REP) => {
          if (REP.offset === undefined)
            return {
              ...REP,
              offset: moment().utcOffset(),
            };
          return REP;
        }); // Remove this when database is cleaned.
        setRepArr(patchREPS); // For Rendering only
        setSavedArr(patchREPS); // Ensure "Saved" === "Unsaved"
        setEditArr(Array.from({ length: REPS.length }, () => false));
        setBoolean(Array.from({ length: REPS.length }, () => booleanTemplate));
      }
    }
  }, [REPS, booleanTemplate, handleAdd]);

  const saveCheck = booleanSaved.map((booleanArr) =>
    Object.values(booleanArr).reduce((accum, bool) => accum && bool, true),
  );
  useEffect(() => {
    if (saveCheck.reduce((accum, bool) => accum && bool, true)) {
      window.onbeforeunload = undefined;
    } else {
      window.onbeforeunload = () => true;
    }
  }, [saveCheck]);

  function setEdit(bool, idx) {
    setEditArr((prevState) => {
      const copyArr = [...prevState];
      copyArr[idx] = bool;
      return copyArr;
    });
  }

  function handleSave(idx, obj) {
    // When the "Save" is clicked,
    // Apply changes to the saved array
    delete obj.local;
    setRepArr((prevState) => {
      const copyArr = [...prevState];
      copyArr[idx] = obj;
      return copyArr;
    });
    setSavedArr((prevState) => {
      const copyArr = [...prevState];
      copyArr[idx] = obj;
      return copyArr;
    });
    setBoolean((prevState) => {
      const copyArr = [...prevState];
      copyArr[idx] = {
        ...copyArr[idx],
        name: true,
        email: true,
        link: true,
        desc: true,
        veto: true,
      };
      return copyArr;
    });
  }

  function passOffset(offset, repIdx) {
    setRepArr(() => {
      const copyArr = [...repArr];
      copyArr[repIdx] = {
        ...copyArr[repIdx],
        offset,
      };
      return copyArr;
    });
  }

  function handleInput(obj, repIdx) {
    const { name, email, link, desc } = obj;
    setBoolean((prevState) => {
      const copyArr = [...prevState];
      copyArr[repIdx] = {
        ...copyArr[repIdx],
        name: savedArr[repIdx].name === name,
        email: savedArr[repIdx].email === email,
        link: savedArr[repIdx].link === link,
        desc: savedArr[repIdx].desc === desc,
      };
      return copyArr;
    });
    setRepArr(() => {
      const copyArr = [...repArr];
      copyArr[repIdx] = {
        ...copyArr[repIdx],
        name,
        email,
        link,
        desc,
      };
      return copyArr;
    });
  }

  function changeApp(slot_id, idx) {
    changeVeto(false, slot_id, idx);
    setRepArr((prevState) => {
      const copyArr = [...prevState];
      copyArr[idx] = {
        ...copyArr[idx],
        appointments: [...copyArr[idx].appointments, { id: null, slot_id }],
      };
      return copyArr;
    });
  }

  function changeVeto(checked, value, idx) {
    setRepArr((prevState) => {
      const copyArr = [...prevState];
      copyArr[idx] = {
        ...copyArr[idx],
        veto: checked
          ? [...copyArr[idx].veto, value]
          : copyArr[idx].veto.filter((val) => val !== value),
      };
      return copyArr;
    });
    setBoolean((prevState) => {
      const copyArr = [...prevState];
      const newVeto = checked
        ? [repArr[idx].veto, value]
        : repArr[idx].veto.filter((val) => val !== value);
      copyArr[idx] = {
        ...copyArr[idx],
        veto: JSON.stringify(savedArr[idx].veto) === JSON.stringify(newVeto),
      };
      return copyArr;
    });
  }

  function changeLanguage(idx) {
    setSelectedIndex(idx);
    const lng = filteredLang[idx].code;
    window.localStorage.setItem('locale', lng);
    i18n.changeLanguage(lng);
  }

  const handleDelete = async (index) => {
    if (repArr[index].id !== null) {
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/delete/representative`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ rep_id: repArr[index].id, ex_id: EX.id }),
        },
      )
        .then((val) => val.json())
        .catch((e) => {
          console.log(e.message);
        });
      if (!res.success) {
        alert('Failed to delete: ', res.message);
        return;
      }
    }

    setRepArr((prevState) => prevState.filter((arr, idx) => index !== idx));
    setSavedArr((prevState) => prevState.filter((arr, idx) => index !== idx));
    setEditArr((prevState) => prevState.filter((arr, idx) => index !== idx));
    setBoolean((prevState) => prevState.filter((arr, idx) => index !== idx));
    setDeletedIdx((prevState) => [...prevState, index]);
  };

  const goBack = useCallback(() => {
    window.location.href = `/exhibitor/${EX.id}`;
  }, [EX.id]);

  return (
    <LangCodeContext.Provider value={CODE}>
      <Container
        component="main"
        maxWidth="lg"
        style={{ minWidth: '1200px', overflow: 'auto' }}
      >
        {EVENT !== null && EX !== null && REPS !== null ? (
          <div>
            <div className={classes.root}>
              <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                  <div
                    className={desktop ? classes.barLeft : null}
                    style={{ width: '250px', textAlign: 'left' }}
                  >
                    {hasApprovalMechanism && (
                      <IconButton color="inherit" onClick={goBack}>
                        <KeyboardBackspaceIcon />
                      </IconButton>
                    )}
                  </div>
                  <div>
                    <Typography variant="subtitle1" className={classes.title}>
                      {EVENT.name}
                    </Typography>
                    <Typography variant="h5" className={classes.title}>
                      {t('editAvail')}
                    </Typography>
                  </div>
                  <div style={{ width: '250px', textAlign: 'right' }}>
                    <IconButton color="inherit" onClick={helpWidget}>
                      <HelpOutlineIcon style={{ fill: 'white' }} />
                    </IconButton>
                    <IconButton color="inherit" disabled>
                      <TranslateIcon style={{ fill: 'white' }} />
                    </IconButton>
                    <Select
                      value={filteredLang[selectedIndex].name}
                      disableUnderline
                      style={{ color: 'white' }}
                    >
                      {filteredLang.map((option, idx) => (
                        <MenuItem
                          key={option.name}
                          value={option.name}
                          onClick={() => changeLanguage(idx)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Toolbar>
              </AppBar>
            </div>

            <div className={classes.paper}>
              <Typography variant="h6" className={classes.heading}>
                {t('eventDetails')}
              </Typography>
              <Typography variant="body1">
                {moment(EVENT.start).format('YYYY-MM-DD') ===
                moment(EVENT.end).format('YYYY-MM-DD')
                  ? toLocaleDateString(EVENT.start, CODE)
                  : `${toLocaleDateString(
                      EVENT.start,
                      CODE,
                    )} – ${toLocaleDateString(EVENT.end, CODE)}`}
              </Typography>
              <Typography variant="body1">
                {`${toLocaleTimeString(
                  EVENT.start,
                  CODE,
                )} – ${toLocaleTimeString(
                  EVENT.end,
                  CODE,
                )} (${getTimeZoneString(CODE)})`}
              </Typography>
              <Typography variant="body1">
                {t('duration', {
                  duration: EVENT.slot_duration,
                })}
              </Typography>
              {!shouldSuspendMultipleRepresentative && (
                <Typography variant="h6" className={classes.heading}>
                  {t('team')}
                </Typography>
              )}
              <div style={{ width: '100%', marginTop: '20px' }}>
                {repArr.length === 0 ? (
                  <Typography
                    variant="h6"
                    className={classes.body}
                    style={{ fontWeight: 400 }}
                  >
                    {t('oops')}
                  </Typography>
                ) : (
                  repArr.map((rep, idx) => {
                    return (
                      <Representative
                        key={`rep-${idx}`}
                        idx={idx}
                        data={rep}
                        changeVeto={changeVeto}
                        handleInput={handleInput}
                        handleSave={handleSave}
                        handleDelete={handleDelete}
                        edit={editArr[idx]}
                        setEdit={setEdit}
                        length={repArr.length}
                        deletedIdx={deletedIdx}
                        passOffset={passOffset}
                        changeApp={changeApp}
                        booleanSaved={booleanSaved[idx]}
                      />
                    );
                  })
                )}
                {!shouldSuspendMultipleRepresentative && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleAdd}
                    fullWidth
                  >
                    {t('addRep')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="abs-center">
            <CircularProgress />
          </div>
        )}
      </Container>
    </LangCodeContext.Provider>
  );
}

export default Edit;
