import React, { useContext, useEffect, useState } from 'react';
import { EventContext, LangCodeContext } from '../Context';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import CalendarSlot from './CalendarSlot';
import {
  toLocaleDateString,
  toLocaleTimeString,
  offsetString,
} from '../../Functions';
import moment from 'moment';
import _ from 'lodash';
import Snack from './Snack';

const useStyles = makeStyles((theme) => ({
  invalid: {
    height: 'calc(100% - 16px)',
    padding: theme.spacing(1),
    textAlign: 'right',
    color: 'grey',
    backgroundColor: 'rgb(230, 230, 230)',
    borderRadius: 0,
  },
  valid: {
    padding: theme.spacing(1),
    minHeight: '8rem',
    textAlign: 'right',
    borderRadius: 0,
  },
  elevation: {
    padding: theme.spacing(1),
    minHeight: '8rem',
    textAlign: 'right',
    borderRadius: 0,
    position: 'sticky',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  item: {
    marginLeft: '-1px',
    border: '1px solid rgb(180, 180, 180)',
  },
  content: {
    borderRadius: '8px',
    textAlign: 'center',
    fontSize: '14px',
    backgroundColor: theme.palette.secondary.light,
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
    color: theme.palette.secondary.main,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  slotHeadings: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  slot: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  slotTitle: {
    padding: '2rem 1.5rem 0 1.5rem',
  },
  label: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
}));

function CalendarItem({
  data,
  repIdx,
  edit,
  changeVeto,
  vetoed,
  appointments,
  asset,
}) {
  const { t } = useTranslation();
  const EVENT = useContext(EventContext);
  const CODE = useContext(LangCodeContext);
  const { START } = asset;
  const ITEM_DATE = moment(data.date)
    .hour(START.hour())
    .minute(START.minute())
    .second(0);
  const slotIdxs = asset.getDateSequence(ITEM_DATE);
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [erase, setErase] = useState(false);
  const [revert, setRevert] = useState(false);
  const [changes, setChanges] = useState([]);
  const [numCheck, setNumCheck] = useState(slotIdxs.length - vetoed.length);
  const [snack, setSnack] = useState(false);
  const blocks = genBlocks(vetoed);
  const classes = useStyles();
  const ITEM_APPOINTMENTS = _.intersection(appointments, data.slotIdxs);
  const LAST_ID =
    ITEM_APPOINTMENTS.length > 0
      ? ITEM_APPOINTMENTS[ITEM_APPOINTMENTS.length - 1]
      : null;

  useEffect(() => {
    setNumCheck(slotIdxs.length - vetoed.length);
  }, [open, slotIdxs.length, vetoed.length]);
  // For "Revert to Default" and "Clear All"
  function handleChange(checked) {
    if (checked) setNumCheck((prevState) => prevState - 1);
    else setNumCheck((prevState) => prevState + 1);
  }

  // State changes BEFORE "Apply" and "Cancel"
  function localChange(checked, value) {
    setChanges((prevState) => [...prevState, [checked, value]]);
  }

  // When "Cancel" is clicked"
  function cancelChanges() {
    setChanges((prevState) => prevState.filter(() => false));
  }

  // When "Apply" is clicked"
  function applyChanges() {
    changes.forEach((val) => changeVeto(val[0], val[1], repIdx));
    cancelChanges();
  }

  function dateFromIndex(idx) {
    const time = ITEM_DATE.clone().add(idx * EVENT.slot_duration, 'minutes');
    return ITEM_DATE.clone().hour(time.hour()).minute(time.minute());
  }

  function genBlocks(vetoArr) {
    if (!data.valid) return [];
    const availId = data.slotIdxs.filter((val) => !vetoArr.includes(val)); // Slot IDs that are AVAILABLE
    let j = 0;
    const avail = [];
    for (let i = 0; i < data.slotIdxs.length; ++i) {
      if (data.slotIdxs[i] === availId[j]) {
        j += 1;
        avail.push(slotIdxs[i]);
      }
    }

    const LENGTH = avail.length;
    if (LENGTH > 0) {
      let currIdx = avail[0];
      const block = [];
      for (let i = 1; i < LENGTH; ++i)
        if (avail[i] !== avail[i - 1] + 1) {
          block.push([currIdx, avail[i - 1] + 1]);
          currIdx = avail[i];
        }

      block.push([currIdx, avail[LENGTH - 1] + 1]);
      return block.map(
        (ele) =>
          `${toLocaleTimeString(
            dateFromIndex(ele[0]),
            CODE,
          )} - ${toLocaleTimeString(dateFromIndex(ele[1]), CODE)}`,
      );
    }

    return [];
  }

  return (
    <Grid item xs className={classes.item}>
      {data.valid ? (
        <Paper
          className={hover ? classes.elevation : classes.valid}
          elevation={hover ? 20 : 0}
          onMouseEnter={() => {
            if (edit) setHover(true);
          }}
          onMouseLeave={() => {
            if (edit) setHover(false);
          }}
          onClick={() => {
            if (edit) setOpen(true);
          }}
        >
          <Typography variant="body1">{ITEM_DATE.date()}</Typography>
          {blocks.slice(0, 3).map((block, idx) => {
            return (
              <Typography
                key={`block-${idx}`}
                variant="body1"
                className={classes.content}
              >
                {block}
              </Typography>
            );
          })}
          {blocks.length > 3 && (
            <Typography
              variant="body1"
              style={{ fontSize: '14px', textAlign: 'center' }}
            >
              {`${t('plusMore', { quantity: blocks.length - 3 })}`}
            </Typography>
          )}
        </Paper>
      ) : (
        <Paper className={classes.invalid} elevation={0}>
          <Typography variant="body1">{ITEM_DATE.date()}</Typography>
        </Paper>
      )}
      {data.valid && (
        <Dialog open={open} fullWidth maxWidth="sm">
          <DialogTitle className={classes.slotTitle}>
            <Typography component="p" variant="h5" style={{ fontWeight: 500 }}>
              {t('editAvailTimeSlots')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="h6">
              {`${toLocaleDateString(ITEM_DATE, CODE)} (${offsetString(
                asset.OFFSET,
              )})`}
            </DialogContentText>
            <div className={classes.root}>
              <Snack
                open={snack}
                onClose={() => setSnack(false)}
                text={t('clearAllMessage')}
                buttonText={t('close')}
              />
              <div className={classes.slotHeadings}>
                <Button
                  disabled={numCheck <= 0}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      LAST_ID !== null &&
                      asset.dateFromSlotId(LAST_ID).valueOf() >
                        moment().valueOf()
                    ) {
                      setSnack(true);
                      return;
                    }

                    setErase(true);
                    setTimeout(() => {
                      setErase(false);
                    }, 200);
                  }}
                >
                  {t('clear')}
                </Button>
                <Button
                  disabled={numCheck >= slotIdxs.length}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setRevert(true);
                    setTimeout(() => {
                      setRevert(false);
                    }, 200);
                  }}
                >
                  {t('revert')}
                </Button>
              </div>
              <Grid container spacing={2}>
                {slotIdxs.map((val, idx) => {
                  return (
                    <CalendarSlot
                      key={`${ITEM_DATE.format('YYYY-MM-DD')}-s${val}`}
                      slot={dateFromIndex(val)}
                      idx={val}
                      handleChange={handleChange}
                      localChange={localChange}
                      available={!vetoed.includes(data.slotIdxs[idx])}
                      erase={erase}
                      revert={revert}
                      id={data.slotIdxs[idx]}
                      booked={ITEM_APPOINTMENTS.includes(data.slotIdxs[idx])}
                    />
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                cancelChanges();
                setOpen(false);
              }}
              color="primary"
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={() => {
                applyChanges();
                setOpen(false);
              }}
              color="primary"
            >
              {t('apply')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
}

export default CalendarItem;
