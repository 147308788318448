import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  ExhibitorContext,
  EventContext,
  RepContext,
  CustomContext,
  AppContext,
} from '../Context';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import { custom } from '../../Functions';
import { useParams } from 'react-router';
import AppointmentList from './AppointmentList';
import ExhibitorEdit from './Edit';

import { api } from '../../services/api';

function Exhibitor() {
  const { id: EX_ID } = useParams();
  const location = useLocation();
  const auth_token =
    queryString.parse(location.search).auth_token ||
    window.localStorage.getItem(EX_ID);
  const [exDetails, setEx] = useState(null);
  const [eventDetails, setEvent] = useState(null);
  const [reps, setReps] = useState(null);
  const [authenticated, setAuth] = useState(false);
  const [error, setError] = useState(false);

  const encodedAttendee =
    queryString.parse(location.search)?.attendee ||
    window.localStorage.getItem('attendee');

  useEffect(() => {
    (async () => {
      try {
        const authRes = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/authenticate/exhibitor`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ auth_token, ex_id: EX_ID }),
          },
        )
          .then((val) => val.json())
          .catch((e) => {
            console.log(e.message);
          });
        if (authRes.success) {
          setAuth(true);
          window.localStorage.setItem(EX_ID, auth_token);
          if (encodedAttendee) {
            window.localStorage.setItem('attendee', encodedAttendee);
          }
        } else setError(true);
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [EX_ID, auth_token, encodedAttendee]);

  useEffect(() => {
    (async () => {
      try {
        if (authenticated) {
          const repRes = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/get_all/representative/${EX_ID}`,
          )
            .then((val) => val.json())
            .catch((e) => console.log(e.message));
          if (repRes.success) setReps(repRes.data);
          else alert(repRes.message);
        }
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [EX_ID, authenticated]);

  useEffect(() => {
    (async () => {
      try {
        if (authenticated) {
          const exRes = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/get/exhibitor/${EX_ID}`,
          )
            .then((val) => {
              return val.json();
            })
            .catch((e) => console.log(e.message));
          if (exRes.success) setEx(exRes.data);
          else alert(exRes.message);
        }
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [EX_ID, authenticated]);

  useEffect(() => {
    (async () => {
      try {
        if (exDetails !== null) {
          const evRes = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/get/event/${exDetails.event_id}`,
          )
            .then((val) => val.json())
            .catch((e) => console.log(e.message));
          if (evRes.success) setEvent(evRes.data);
          else alert(evRes.message);
        }
      } catch (e) {
        console.log(e.message);
        setError(true);
      }
    })();
  }, [exDetails]);

  const {
    data: appsRes,
    refetch: appsRefetch,
    dataUpdatedAt: lastUpdated,
  } = useQuery(
    ['get-all-appointments'],
    async () =>
      api.appointment.getAllAppointmentByExIdAndRepId({
        ex_id: EX_ID,
        rep_id: reps[0].id,
      }),
    {
      enabled: Boolean(reps !== null && reps.length),
      refetchOnWindowFocus: true,
    },
  );

  if (error) return <Redirect to="/error" />;

  const { hasApprovalMechanism } =
    (eventDetails && eventDetails.feature_flag) ?? {};

  if (
    (eventDetails &&
      hasApprovalMechanism &&
      window.location.pathname === `/exhibitor/${EX_ID}`) ||
    window.location.pathname === `/exhibitor/${EX_ID}/`
  )
    return <Redirect to={`/exhibitor/${EX_ID}/appointments`} />;

  return eventDetails !== null && exDetails !== null && reps !== null ? (
    <EventContext.Provider value={eventDetails}>
      <ExhibitorContext.Provider value={exDetails}>
        <RepContext.Provider value={reps}>
          {hasApprovalMechanism && appsRes !== undefined ? (
            <AppContext.Provider
              value={{
                data: appsRes,
                refetch: appsRefetch,
                lastUpdated,
              }}
            >
              <CustomContext.Provider value={custom(eventDetails)}>
                <Router>
                  <Switch>
                    <Route
                      path={`/exhibitor/${EX_ID}/appointments`}
                      exact
                      component={AppointmentList}
                    />
                    <Route
                      path={`/exhibitor/${EX_ID}/edit`}
                      exact
                      component={ExhibitorEdit}
                    />
                    <Route path="*" exact component={Error} />
                  </Switch>
                </Router>
              </CustomContext.Provider>
            </AppContext.Provider>
          ) : Boolean(hasApprovalMechanism) === false ? (
            <ExhibitorEdit />
          ) : (
            <div className="abs-center">
              <CircularProgress />
            </div>
          )}
        </RepContext.Provider>
      </ExhibitorContext.Provider>
    </EventContext.Provider>
  ) : (
    <div className="abs-center">
      <CircularProgress />
    </div>
  );
}

export default Exhibitor;
